import statistics from '../../api/statistics'

const state = () => ({
  data: [],
  labels: [], // x axis
  config: [],
  last: []
})

const getters = {
  data(state) {
    return state.data
  },
  labels(state) {
    return state.labels
  },
  config(state) {
    return state.config
  },
  last (state) {
    return state.last
  }
}

const mutations = {
  setData: (state, data) => {
    if (Array.isArray(data)) {
      var lastMin = null; 
      var min; 
      var filteredData = [];
      var lbls = [];

      data.forEach(function (el) {
        min = el.date.slice(11, 16)
        if (min != lastMin) {
          el.date = min
          filteredData.push(el)
          lbls.push(min)
        }
        lastMin = min
      })

      var transposedData = [];

      if (filteredData[0]) {
        Object.keys(filteredData[0]).forEach(function (item) {
          if (item != 'date') {
            transposedData[item] = filteredData.map(function (el) {
              return { x: el.date, y: el[item] }
            })
          }
        })
      }

      state.data = transposedData
      state.labels = lbls
    } else {
      state.data = [];
    }
  },
  setConfig (state, data) {
    state.config = data
  },
  setLast (state) {
    const curr = []
    for (const key in state.data) {
      // if (state.data.hasOwnProperty(key)) {
        curr[key] = state.data[key].slice(-1)[0]
      // }
    }
    state.last = curr 
  }
}

const actions = {
  getAll({commit}, {controllerId, date, pathpart}) {
    commit('setLoading', true, { root: true });
    
    return statistics.getAll(controllerId, date, pathpart)
    .then(data => {
      
      commit('setConfig', data.config ?? {});
      commit('setData', data.data ?? {});

      const currDate = new Date().toJSON().slice(0, 10);
      if (date == currDate) {
        commit('setLast')
      }
      
      commit('setLoading', false, { root: true });
      
      return data;
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}