<template>
  <v-dialog v-model="editDialog" :fullscreen="isMobile" :hide-overlay="isMobile" persistent :transition="(isMobile) ? 'dialog-bottom-transition' : 'dialog-transition'" :width="(!isMobile) ? 700 : 'auto'">
    <v-card class="d-flex flex-column justify-space-between">
      <v-toolbar v-if="!isSchedule" flat class="nobg flex-initial">
        <v-btn v-if="isMobile" icon @click="close" color="primary">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{ config.label }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close" color="primary" v-if="!isMobile">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      
      <!--<schedule-select :paramName="editDialogParam" :reset="resetScheduleSelect" @change-schedule="changeSchedule" />-->
      <!--<v-fade-transition mode="out-in">-->
      <component :is="setterComponent" :paramName="editDialogParam" :onClose="close" ></component>
      <!--</v-fade-transition>-->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Parameter from './Parameter.vue'
import ParameterOptions from './ParameterOptions.vue'
import ParameterConfirm from './ParameterConfirm.vue'
import ParameterSchedule from './ParameterSchedule.vue'
import ParameterTemporary from './ParameterTemporary.vue'
import ParameterText from './ParameterText.vue'
import ScheduleName from './ScheduleName.vue'
// import ScheduleSelect from './ScheduleSelect.vue'
export default {
  components: {
    Parameter,
    ParameterOptions,
    ParameterConfirm,
    ParameterSchedule,
    ParameterTemporary,
    ParameterText,
    ScheduleName
    // ScheduleSelect
  },
  data: () => ({
    // manualSchedule: undefined,
    // resetScheduleSelect: undefined
	}),
  computed: {
    ...mapGetters('parameters', ['editDialog', 'editDialogParam', 'paramValue', 'paramConfig']),
    ...mapGetters('schedules', ['getSchedule']),
    ...mapGetters(['isMobile']),
    config() {
      return (this.editDialogParam) ? this.paramConfig(this.editDialogParam) : {}
    },
    isTemporary() {
      return !!this.config.params && !!this.config.options
    },
    isSchedule() {
      return !!this.config.params
    },
    isText() {
      return !this.config.unit && !this.config.params && !this.config.options
    },
    scheduleVal() {
      if (this.config.schedule) {
        let data = this.paramValue(this.config.schedule);
        return (data) ? data : null
      } else {
        return null
      }
    },
    setterComponent() {
      if (this.isTemporary) {
        return 'ParameterTemporary'
      } else if (this.isSchedule) {
        return 'ParameterSchedule'
      } else if (this.config.options) {
        return 'ParameterOptions'
      } else if (this.config.confirmation) {
        return 'ParameterConfirm'
      } else if (this.isText) {
        return 'ParameterText'
      } else {
        return 'Parameter'
      }
    },
  },
  watch: {
    editDialog() {
      // this.manualSchedule = undefined;
      // this.resetScheduleSelect = new Date().getTime();
    }
  },
  methods: {
    ...mapMutations('parameters', ['editDialogClose']),
    close () {
      //this.loading = false;
      this.editDialogClose();
      this.manualSchedule = undefined;
    },
    changeSchedule(idSchedule) {
      this.manualSchedule = idSchedule
    }
  }
}
</script>