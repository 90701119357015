<template>
  <v-container
		class="fill-height flex-column justify-start px-0 pt-0"
		fluid
	>
    <div v-if="!loading" class="w-100">
      <v-list v-if="dataCount > 0" two-line width="100%" class="nobg pt-0">
        <v-list-item-group>
          <v-divider key="01"></v-divider>
          <template v-for="(item, i) in all">
          <v-list-item class="px-6" :key="i" @click.stop="edit(item)" color="primary">
            <v-list-item-avatar>
              <v-icon class="secondary">person</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.email }}</v-list-item-title>
              <v-list-item-subtitle class="font-weight-light">{{ permissions[item.permission] }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="i+'d'"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
      <div v-else class="w-100">
        <v-divider></v-divider>
        <div class="text-h6 text-center pt-8 px-5">{{ locales['error-25'] }}</div>
      </div>

      <div v-if="dataCount > 0" class="action-floating px-8 px-md-6 text-right pt-md-4">
        <v-fade-transition>
          <v-btn v-show="showFab" @click="add" x-large rounded color="primary" :outlined="!isMobile"><v-icon>add</v-icon><span v-if="!isMobile">{{ locales['add-user'] }}</span></v-btn>
        </v-fade-transition>
      </div>
    </div>
  
    <v-dialog
      v-model="dialogEdit"
      :fullscreen="isMobile" 
      :hide-overlay="isMobile" 
      persistent 
      :transition="(isMobile) ? 'dialog-bottom-transition' : 'dialog-transition'" :width="(!isMobile) ? 700 : 'auto'"
    >
      <v-card class="rounded-lg d-flex flex-column">
        <v-toolbar flat class="nobg flex-shrink-1 flex-grow-0">
          <v-btn icon @click="dialogEdit = false" color="primary" v-if="isMobile">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ locales['user-edit'] }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogEdit = false" color="primary" v-if="!isMobile">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-8 flex-grow-1 d-flex align-center ">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            autocomplete="off"
            class="w-100"
          >
            <v-text-field
              v-model="edited.email" 
              required 
              :rules="emailRules"
              label="E-mail"
              name="email"
              prepend-inner-icon="person"
              type="email"
            ></v-text-field>
            <v-radio-group v-model="edited.permission" class="mt-2">
              <v-radio :label="locales['owner']" :value=0 color="primary"></v-radio>
              <v-radio :label="locales['full-control']" :value=1 color="primary"></v-radio>
              <v-radio :label="locales['read-only']" :value=2 color="primary"></v-radio>
              <v-radio :label="locales['no-permission']" :value=3 color="primary"></v-radio>
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions class="flex-shrink-1 pb-5 pb-sm-6 px-3" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
          <v-btn x-large rounded text :color="(isMobile) ? 'primary' : 'secondary'" @click.stop="dialogEdit = false" class="mr-1">{{ locales['cancel'] }}</v-btn>
          <v-btn x-large rounded :text="!isMobile" color="primary" @click.stop="save" :loading="loading" class="px-16 px-md-6">{{ locales['save'] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: ['controllerId', 'pathpart'],
  data () {
    return {
      showFab: false,
      dialogEdit: false,
      edited: {},
      valid: true,
      emailRules: [
        v => !!v || this.locales['error-1'],
        v => /.+@.+\..+/.test(v) || this.locales['error-2'],
      ],
      permission: false
    }
  },
  computed: {
    ...mapGetters('users', ['all']),
    ...mapGetters('controllers', ['byId']),
    ...mapGetters(['isMobile','loading','clock']),
    ...mapGetters('locales', ['locales']),
    controllerById() {
      return this.byId(this.controllerId)
    },
    dataCount() {
      return parseInt(this.all.length)
    },
    permissions() {
      return [
        this.locales['owner'],
        this.locales['full-control'],
        this.locales['read-only'],
        this.locales['no-permission']
      ]
    }
  },
  beforeMount() {
    if (!this.controllerById) {
      this.$router.push('/controllers')
    }
  },
  mounted() {
    this.setPageTitle(this.locales['users']);
    this.load()
    .then(data => {
      if (data.error) {
        this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
      }
    });
    window.setTimeout(() => {
      this.showFab = true
    }, 200);
    console.log(this.pathpart)
  },
  beforeDestroy() {
    this.setPageTitle(undefined);
  },
  watch: {
    clock() {
      this.load()
    },
    pathpart() {
      this.load()
    }
  },
  methods: {
    ...mapActions('users', ['getAll','update']),
    ...mapMutations(['setPageTitle']),
    load() {
      return this.getAll({
        controllerId: this.controllerId, 
        pathpart: this.pathpart
      })
    },
    edit(item) {
      this.edited = item;
      this.edited.permission = parseInt(item.permission);
      this.dialogEdit = true;
    },
    add() {
      this.edit({
        email: undefined,
        permission: undefined
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.update({
          controllerId: this.controllerId,
          email: this.edited.email,
          permission: this.edited.permission
        })
        .then(data => {
          if (data.error) {
            this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
					} else {
            this.$store.commit('snack/open', { color: "success", text: this.locales['changes-saved'] });
            this.dialogEdit = false;
					}
				});
      }
    }
  }
}
</script>
