<template>
  <v-container
		class="fill-height flex-column px-0 app-container"
		fluidx
	>
    <div class="d-flex justify-start align-end w-100 mb-3 px-2">
      <div><svg class="hero-img embed" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 10.896 11.248"><defs><linearGradient id="a" x1=".502" y1="1.024" x2=".502" y2=".016" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#068dce"/><stop offset="1" stop-color="#2bb1e6"/></linearGradient><linearGradient id="b" x1="-43.473" y1="-558.949" x2="-43.473" y2="-556.311" xlink:href="#a"/><linearGradient id="c" x1="-36.963" y1="-532.639" x2="-36.963" y2="-529.416" xlink:href="#a"/><linearGradient id="d" x1="-45.182" y1="-561.147" x2="-45.182" y2="-558.52" xlink:href="#a"/></defs><path data-name="Path 1" d="M8.68 9.14V6.53a.67.67 0 1 0-1.33 0v2.61a1.13 1.13 0 0 0-.45.86 1.12 1.12 0 0 0 2.23 0 1.13 1.13 0 0 0-.45-.86Z" fill="url(#a)" transform="translate(-2.551 -2.042)"/><path class="negativ" data-name="Path 2" d="M.389 5.738a.39.39 0 0 1-.27-.67l5-4.89a.62.62 0 0 1 .87 0l4.79 4.78a.392.392 0 0 1-.55.56L5.519.848l-4.85 4.78a.43.43 0 0 1-.28.11Z" fill="#fff"/><path data-name="Path 3" d="M8.249 11.248h-5.45a1.25 1.25 0 0 1-1.25-1.29v-3.83a.39.39 0 0 1 .39-.39.4.4 0 0 1 .39.39v3.83a.47.47 0 0 0 .47.47h5.45a.47.47 0 0 0 .47-.47v-3.83a.4.4 0 0 1 .39-.39.39.39 0 0 1 .39.39v3.83a1.25 1.25 0 0 1-1.25 1.29Z" fill="#26ade3"/><path class="negativ" data-name="Path 4" d="M5.669 7.538v-1.82a.205.205 0 0 0-.41 0v1.82a.505.505 0 1 0 .41 0Z" fill="#fff"/><path data-name="Path 5" d="M6.76 6.85a.17.17 0 0 1 .17.15.18.18 0 0 1-.17.17H6.2A.18.18 0 0 1 6 7a.17.17 0 0 1 .17-.17Z" fill="url(#b)" transform="translate(-2.551 -2.042)"/><path data-name="Path 6" d="M6.76 7.64a.18.18 0 0 1 .17.18.18.18 0 0 1-.17.18H6a.18.18 0 0 1-.18-.17.18.18 0 0 1 .18-.19Z" fill="url(#c)" transform="translate(-2.551 -2.042)"/><path data-name="Path 7" d="M6.76 8.44a.18.18 0 0 1 .17.17.17.17 0 0 1-.17.17H6.2a.17.17 0 0 1-.2-.17.18.18 0 0 1 .17-.17Z" fill="url(#d)" transform="translate(-2.551 -2.042)"/></svg></div>
      <div class="pl-4 text-h5 font-weight-bold">{{ locales['my-devices'] }}</div>
    </div>
    <v-fade-transition>
      <v-container fluid v-show="hasControllers" class="mb-3">
        <v-row dense>
          <v-col v-for="(item, i) in all" :key="i" cols="6" md="4">
            <controller :data="item" @deleteClick="onDeleteClick" @message="onMessage" />
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>
    <div v-if="message" class="w-100 text-center text-md-left px-3 mt-3">
      <v-alert prominent class="px-8 py-10 r-16">
        <div class="text-center"><v-icon size="56" class="mb-3">contact_support</v-icon></div>
        <div class="text-center mb-6">{{ message }}</div>
        <v-btn @click.stop="dialogEdit = true" color="primary" x-large rounded class="w-100"><v-icon>add</v-icon><span>Dodaj urządzenie</span></v-btn>
        <v-btn @click.stop="dialogDemo = true" x-large rounded outlined class="w-100 mt-2"><v-icon>add</v-icon>DEMO</v-btn>
      </v-alert>
    </div>
    <div v-if="errorMessage" class="py-6 px-3">
      <v-alert class="r-16">
        <div class="text-center px-5 pb-3">{{ errorMessage }}</div>
        <div class="text-center">
          <v-btn rounded outlined color="primary" @click="refrList" :loading="loading">{{ locales['refresh'] }}</v-btn>
        </div>
      </v-alert>
    </div>
    <div v-if="hasControllers" class="action-floatingx px-3 pt-md-4 w-100">
      <v-btn v-show="showFab" @click.stop="dialogEdit = true" color="primary" x-large rounded outlined><v-icon>add</v-icon></v-btn>
      <v-btn v-show="showFab" @click.stop="dialogDemo = true" x-large rounded outlined class="ml-2"><v-icon>add</v-icon>DEMO</v-btn>
    </div>

    <v-dialog
      v-model="dialogEdit"
      :fullscreen="isMobile" 
      :hide-overlay="isMobile" 
      persistent 
      :transition="(isMobile) ? 'dialog-bottom-transition' : 'dialog-transition'" :width="(!isMobile) ? 700 : 'auto'"
    >
      <v-card class="rounded-lg d-flex flex-column">
        <v-toolbar flat class="nobg flex-shrink-1 flex-grow-0">
          <v-btn v-if="isMobile" icon @click="dialogEdit = false" color="primary">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>Dodaj sterownik</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="!isMobile" icon @click="dialogEdit = false" color="primary">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-8 flex-grow-1 d-flex align-center w-100">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            autocomplete="off"
            class="w-100"
          >
            <v-text-field
              v-model="newControllerId" 
              required 
              :rules="[rules.required, rules.len16]"
              label="ID sterownika"
              type="text"
              counter
            ></v-text-field>
            <v-text-field
              v-model="displayName" 
              required 
              :rules="[rules.required, rules.min3, rules.max25]"
              label="Nazwij swoje urządzenie"
              type="text"
            ></v-text-field>
            <div class="text--disabled mt-8">&ast; ID sterownika to 16-znakowy kod, który znajdziesz na obudowie urządzenia</div>
          </v-form>
        </v-card-text>
        <v-card-actions class="flex-shrink-1 pb-5 pb-sm-6 px-3" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
          <v-btn x-large rounded text :color="(isMobile) ? 'primary' : 'secondary'" @click.stop="dialogEdit = false" class="mr-1">{{ locales['cancel'] }}</v-btn>
          <v-btn x-large rounded :text="!isMobile" color="primary" @click.stop="save" :loading="loading" class="px-16 px-md-6">{{ locales['save'] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDemo"
      :fullscreen="isMobile" 
      :hide-overlay="isMobile" 
      persistent 
      :transition="(isMobile) ? 'dialog-bottom-transition' : 'dialog-transition'" :width="(!isMobile) ? 700 : 'auto'"
    >
      <v-card class="rounded-lg d-flex flex-column">
        <v-toolbar flat class="nobg flex-shrink-1 flex-grow-0">
          <v-btn v-if="isMobile" icon @click="dialogDemo = false" color="primary">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>Dodaj sterownik DEMO</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="!isMobile" icon @click="dialogDemo = false" color="primary">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-8 flex-grow-1 d-flex align-center w-100">
          <v-form
            ref="formdemo"
            v-model="validDemo"
            lazy-validation
            autocomplete="off"
            class="w-100"
          >
            <v-select
              v-model="demo"
              :items="demos"
              item-text="label"
              item-value="id"
              label="Wybierz sterownik"
              required
              :rules="[rules.required]"
            ></v-select>
            <v-text-field
              v-model="displayName" 
              required 
              :rules="[rules.required, rules.min3, rules.max25]"
              label="Nadaj własną nazwę sterownikowi"
              type="text"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="flex-shrink-1 pb-5 pb-sm-6 px-3" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
          <v-btn x-large rounded text :color="(isMobile) ? 'primary' : 'secondary'" @click.stop="dialogDemo = false" class="mr-1">{{ locales['cancel'] }}</v-btn>
          <v-btn x-large rounded :text="!isMobile" color="primary" @click.stop="saveDemo" :loading="loading" class="px-16 px-md-6">{{ locales['save'] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="290"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 nobreak">
          {{ locales['remove-device'] }}
        </v-card-title>
        <v-card-text>
          <p class="mt-2">ID {{ toDeleteId }}<br />
          {{ toDeleteName }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialogDelete = false"
          >
            {{ locales['cancel'] }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="removeController"
          >
            {{ locales['no-permission'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Controller from '@/components/Controller.vue';
export default {
  components: {
    Controller
  },
  data () {
    return {
      showFab: false,
      dialogEdit: false,
      valid: true,
      newControllerId: '',
      displayName: '',
      rules: {
        required: value => !!value || 'Wypełnij to pole',
        len16: value => value.length == 16 || 'Powinno być 16 znaków',
        min3: value => value.length >= 3 || 'Min. 3 znaki',
        max25: value => value.length <= 25 || 'Max 25 znaków'
      },
      message: undefined,
      errorMessage: undefined,
      refreshInterval: undefined,
      dialogDelete: false,
      toDeleteId: null,
      toDeleteName: null,
      dialogDemo: false,
      demo: undefined,
      validDemo: true
    }
  },
  computed: {
    ...mapGetters('controllers', ['all','byId']),
    ...mapGetters(['isMobile','loading','clock']),
    ...mapGetters('locales', ['locales']),
    hasControllers() {
      return (this.all.length > 0) ? true : false
    },
    demos() {
      return [
        { id: 1, label: this.locales['recuperator'] },
        { id: 2, label: this.locales['pellet'] },
      ]
    }
  },
  watch: {
    clock() {
      this.refrList()
    }
  },
  mounted() {
    window.setTimeout(() => {
      this.showFab = true
    }, 200);
    
    this.refrList();
    /*this.refreshInterval = setInterval(() => {
      this.refrList();
    }, 15000) */
  },
  beforeRouteLeave(o, from, next) {
    this.showFab = false;
    // clearInterval(this.refreshInterval) // stop listening list
    next();
  },
  methods: {
    ...mapActions('controllers', ['getAll','create','remove']),
    ...mapActions('profile', ['logout']),
    save() {
			if (this.$refs.form.validate()) {
				this.create({
          controllerId: this.newControllerId,
          displayName: this.displayName,
          demo: null
				})
				.then(data => {
					if (data.error) {
            this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
					} else {
						this.$store.commit('snack/open', { color: "success", text: this.locales['changes-saved'] });
            this.dialogEdit = false;
            this.message = false;
					}
				});
			}
    },
    saveDemo() {
			if (this.$refs.formdemo.validate()) {
				this.create({
          controllerId: '0000000000000000',
          demo: this.demo,
          displayName: this.displayName
				})
				.then(data => {
					if (data.error) {
            this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
					} else {
						this.$store.commit('snack/open', { color: "success", text: this.locales['changes-saved'] });
            this.dialogDemo = false;
            this.message = false;
					}
				});
			}
    },
    refrList() {
      this.getAll()
      .then(data => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
          if (data.error.code == '16') { 
            this.logout();
            this.$router.push({ path: '/login' }) 
          } else {
            this.message = undefined;
            this.errorMessage = this.locales['error-' + data.error.code] || '';
            this.showFab = false;
          }
        } else if (this.all.length == 0) {
          this.message = this.locales['no-devices'];
          this.errorMessage = undefined;
          this.showFab = true;
        } else {
          this.message = undefined;
          this.errorMessage = undefined;
          this.showFab = true;
        }
      });
    },
    onDeleteClick(value) {
      if (value) {
        this.toDeleteId = value
        this.toDeleteName = this.byId(value).displayName
        this.dialogDelete = true
      }
    },
    onMessage(value) {
      this.$store.commit('snack/open', { color: "error", text: this.locales[value] });
    },
    removeController() {
      this.remove({
        controllerId: this.toDeleteId
      })
      .then(data => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
        } else {
          this.$store.commit('snack/open', { color: "success", text: this.locales['changes-saved'] });
          this.dialogDelete = false;
          this.toDeleteId = null
          this.toDeleteName = null

          this.refrList();
        }
      });
    }
  }
}
</script>

<style>
.hero-img {
  width: 90px;
  height: auto;
}
.v-card__title.nobreak {
  word-break: normal;
}
</style>
