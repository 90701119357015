import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken, onMessage, isSupported  } from "firebase/messaging";
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBifwOdnffHPcBGHasuMKvzO2YP55J-DdU",
  authDomain: "epanel24-1.firebaseapp.com",
  projectId: "epanel24-1",
  storageBucket: "epanel24-1.appspot.com",
  messagingSenderId: "798191591193",
  appId: "1:798191591193:web:e9102260db7e09526aef88"
}
/*
const firebaseConfig = {
  apiKey: "AIzaSyBVFj7FZymjx6UnxGa_8lwOhrha6vlSu64",
  authDomain: "epanel24-mess.firebaseapp.com",
  projectId: "epanel24-mess",
  storageBucket: "epanel24-mess.appspot.com",
  messagingSenderId: "308958320707",
  appId: "1:308958320707:web:eb9ef41d071bec3444dcf6"
}; */

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
let messaging = null;
if (isSupported() && 'serviceWorker' in navigator) {
  messaging = getMessaging(app);
}

export default { messaging, getToken, deleteToken, onMessage }
//export default messaging

/*
import { getMessaging, getToken } from "firebase/messaging";
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();
getToken(messaging, { vapidKey: 'BG0G07pN13nUBPHBbhCg_1gVDshBnL9Yh38MftS4n0VKrIlHKDQtDVxpJmPO6zE9sNeQkySxPpKp6NegZQdOrOk' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});*/