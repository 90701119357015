<template>
	<v-container
		class="fill-height flex-column justify-md-center"
		fluid
	>
		<div class="flex-grow-1 flex-md-grow-0 d-flex align-center w-100">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <transition name="fade" mode="out-in">
						<div v-if="success" key="1">
							<div class="text-h5 text--primary mb-8">{{ locales['email-receive'] }}</div>
							<p>{{ locales['email-hint-1'] }}</p>
						</div>
						<div v-else key="2">
							<div class="text-h5 text--primary mb-8">{{ locales['password-remind'] }}</div>
							<v-form
								ref="form"
								v-model="valid"
								autocomplete="off"
								lazy-validation
							>
								<v-text-field
									v-model="email" 
									required 
									:rules="emailRules"
									label="E-mail"
									name="email"
									prepend-inner-icon="alternate_email"
									type="text"
									:loading="loading"
								></v-text-field>
							</v-form>
						</div>
          </transition>
        </v-col>
      </v-row>
    </div>
    <div class="flex-shrink-1 h-action-column w-100 pt-md-12">
			<v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
					<v-btn v-if="!success" x-large rounded color="primary" @click="remindUser" :loading="loading" class="w-100 mb-2">{{ locales['password-remind'] }}</v-btn>
					<v-btn v-if="!success" x-large rounded text color="primary" to="/login" class="w-100 inactive">{{ locales['cancel'] }}</v-btn>
					<v-btn v-if="success" x-large rounded text color="primary" to="/login" class="w-100">{{ locales['login'] }}</v-btn>
				</v-col>
			</v-row>
    </div>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	data: () => ({
		valid: true,
		email: undefined,
		emailRules: [
			v => !!v || 'Podaj adres e-mail',
			v => /.+@.+\..+/.test(v) || 'E-mail jest nieprawidłowy',
		],
		passwd: undefined,
		passwordRules: [ v => !!v || 'Podaj hsło' ],
		passType: false,
		errorMessage: false,
		success: false
	}),
	computed: {
		...mapGetters('profile', ['loading']),
		...mapGetters('locales', ['locales']),
		error() {
			return (this.errorMessage) ? true : false
		}
  },
	methods: {
		...mapActions('profile', ['remind']),
		remindUser() {
			if (this.$refs.form.validate()) {
				this.remind({
					email: this.email
				})
				.then(data => {
					if (data.error) {
						this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
					} else {
						this.success = true;
					}
				});
			}
		}
	}
}
</script>
