<template>
  <v-list-item class="px-6" @click="messagesActive = !messagesActive">
    <v-list-item-icon>
      <v-icon>notifications_active</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ locales['notifications-push'] }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-switch v-model="messagesActive" readonly color="primary"></v-switch>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      isActive: false,
      vapidKey: 'BP1KjJz7Bvo-BYUMmwgHfgTeEGb0e76SI1mzvxypAhQGWH4M_rB2vChqJfG6yhoisWBXpTtt-qNjQeRDvCzdr8o', // BG0G07pN13nUBPHBbhCg_1gVDshBnL9Yh38MftS4n0VKrIlHKDQtDVxpJmPO6zE9sNeQkySxPpKp6NegZQdOrOk
    }
  },
  computed: {
    ...mapGetters('locales', ['locales', 'locale']),
    messagesActive: {
      get: function() {
        return this.isActive
      },
      set: function(val) {
        if (!("Notification" in window)) {
          this.isActive = false
          this.$store.commit('snack/open', { color: "error", timeout: 5000, text: 'This browser does not support desktop notification' })
        } else if (Notification.permission === "denied") {
          this.$store.commit('snack/open', { color: "error", timeout: 20000, text: 'Powiadomienia zablokowano! Otwórz przeglądarkę na swoim telefonie -> wejdź w „Menu” -> kliknij „Ustawienia”, a następnie „Powiadomienia” -> wyszukaj witrynę i włącz przy niej powiadomienia.' })
          this.isActive = false
        } else if (val === true && Notification.permission !== "granted") {
          this.requestPermission()
        } else if (val === true) {
          this.enableMessages()
        } else {
          this.disableMessages()
        }
      }
    },
  },
  mounted() {
    this.setup()
  },
  methods: {
    ...mapActions('profile', ['saveMessagesToken']),
    setup() {
      if (this.isTokenSentToServer()) {
        this.isActive = true
      }
    },
    requestPermission() {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          this.enableMessages()
        }
      });
    },
    enableMessages() {
      this.$messaging.getToken(this.$messaging.messaging, { vapidKey: this.vapidKey }).then((currentToken) => {
        if (currentToken) {
          this.sendTokenToServer(currentToken, '');
          this.sendHello('Witaj! Powiadomienia włączone')
        } else {
          // Show permission request.
          console.log('No registration token available. Request permission to generate one.');
          this.$store.commit('snack/open', { color: "error", timeout: 5000, text: 'Nie udało się włączyć subskrypcji. Spróbuj ponownie' })
          // Show permission UI.
          this.isActive = false;
          this.setTokenSentToServer(false);
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        this.$store.commit('snack/open', { color: "error", timeout: 5000, text: 'Wystąpił błąd podczas pobierania tokena.' + err })
        this.isActive == false;
        this.setTokenSentToServer(false);
      });
    },
    disableMessages() {
      // this.sendTokenToServer('');
      
      this.$messaging.getToken(this.$messaging.messaging, { vapidKey: this.vapidKey }).then((currentToken) => {
        this.sendTokenToServer('', currentToken);
        this.$messaging.deleteToken(currentToken).then(() => {
          // this.sendTokenToServer('');
        }).catch((err) => {
          console.log('Unable to delete token. ', err);
        });
      }).catch((err) => {
        console.log('Error retrieving registration token. ', err);
      });
    },
    isTokenSentToServer() {
      return window.localStorage.getItem('sentToServer') === '1';
    },
    setTokenSentToServer(sent) {
      window.localStorage.setItem('sentToServer', sent ? '1' : '0');
    },
    sendTokenToServer(currentToken, oldToken) {
      console.log('Sending token to server...' + currentToken);
      this.saveMessagesToken({
        messagesToken: currentToken,
        oldToken: oldToken
      })
        .then(data => {
          console.log(data)
          if (!data.error) {
            this.setTokenSentToServer(currentToken !== '');
            this.isActive = (currentToken !== '');
          }
        })
    },
    sendHello(text) {
      this.$store.commit('snack/open', { color: "success", timeout: 3000, text: text })

      // const img = '/img/icons/apple-touch-icon-120x120.png';
      // new Notification('e-panel24', { body: text, icon: img });
      // https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
    },

  }
}
// https://github.com/firebase/quickstart-js/blob/master/messaging/index.html
</script>