//import { getToken } from "./getToken";
//import { badResponse } from "./badResponse";
import { apiUrl, getToken, badResponse } from "./api";

export default {
  getAll(controllerId) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('token', getToken());
    return fetch(apiUrl() + '/sterownik/dane?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(() => badResponse());
  },
  getConfig(controllerId) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('token', getToken());
    return fetch(apiUrl() + '/sterownik/config?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(() => badResponse());
  },
  update(controllerId, paramName, paramValue) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('value', paramValue);
    formData.append('token', getToken());
    
    return fetch(apiUrl() + '/sterownik/' + paramName + '/zapisz?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  updateTemporary(controllerId, paramName, paramValue, time, params) {
    const formData = new FormData()
    formData.append('controllerId', controllerId)
    formData.append(paramName, paramValue)
    formData.append('time', time)

    const keys = Object.keys(params);
    keys.forEach(key => {
      formData.append(key, params[key])
    })
    formData.append('token', getToken())
    
    return fetch(apiUrl() + '/sterownik/' + paramName + '/edytuj?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
}
