<template>
  <v-container
		class="fill-height flex-column justify-start px-0 pt-0"
		fluid
	>
    <div class="px-4 d-flex justify-center align-center">
      <v-btn @click="prevDate" text icon x-large class="mr-1">
        <v-icon>arrow_back_ios_new</v-icon>
      </v-btn>
      <v-menu
        ref="menu"
        v-model="menuDatepicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="date"
            append-icon="expand_more"
            outlined
            readonly
            rounded
            hide-details
            v-on="on"
          >
          </v-text-field>
        </template>
        <v-date-picker v-model="date" @input="menuDatepicker = false" no-title :locale="locale.id"></v-date-picker>
      </v-menu>
      <v-btn @click="nextDate" text icon x-large class="ml-1">
        <v-icon>arrow_forward_ios</v-icon>
      </v-btn>
    </div>
    <div class="chart-container w-100 pa-1 mb-2 relative">
      <canvas id="tempChart" width="320" height="350"></canvas>
    </div>
    <div class="w-100">
      <v-list
        one-line
        :dense="isMobile"
        width="100%"
        class="nobg pt-0"
      >
        <v-list-item-group>
          <v-divider key="01"></v-divider>
          <template v-for="(item, i, idx) in config.datasets">
            <v-list-item
              :key="idx" @click="toggleHide(i)" :class="{ inactive: toHide[i] }"
            >
              <v-list-item-icon>
                <v-icon v-text="(toHide[i] == true) ? 'radio_button_unchecked' : 'radio_button_checked'" :color="datasetsColors[i]"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="pr-2">
                {{ (last[i]) ? last[i].y + ' ' + ((item.unit === 'C') ? '&deg;C' : (item.unit || '')) : '' }}
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="idx+'d'"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </div>

  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

export default {
  props: ['controllerId', 'menuName'],
  data () {
    return {
      chart: {},
      date: new Date().toISOString().substr(0, 10),
      menuDatepicker: false,
      hideDatasets: [],
      now: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    ...mapGetters('controllers', ['byId']),
    ...mapGetters('statistics', ['data', 'labels', 'config', 'last']),
    ...mapGetters(['isMobile', 'loading', 'clock']),
    ...mapGetters('locales', ['locales', 'locale']),
    controllerById() {
      return this.byId(this.controllerId)
    },
    toHide () {
      return this.hideDatasets
    },
    colors () {
      return (this.$vuetify.theme.dark) 
      ? ['#ef5350', '#FF80AB', '#64b5f6', '#A5C6FC', '#1E88E5', '#FF9E80', '#FFFF8D', '#FDD835', '#737e00', '#69F0AE', '#66BB6A', '#D7CCC8', '#B0BEC5', '#BDBDBD'] 
      : ['#ef3024', '#FF4081', '#64b5f6', '#2196F3', '#7C4DFF', '#FF9E80', '#FFEB3B', '#FFC107', '#737e00', '#00E676', '#CDDC39', '#607D8B', '#FF9800', '#FF6E40'] 
    },
    datasetsColors () {
      let colors = []
      const keys = Object.keys(this.config.datasets)

      for (var i = 0; i < keys.length; i += 1) {
        colors[keys[i]] = this.colors[i]
      }

      return colors
    },
    gridColor () {
      return (this.$vuetify.theme.dark) 
      ? 'rgba(255, 255, 255, 0.08)' 
      : 'rgba(255, 0, 0, 0.08)'
    },
    scales() {
      const visibleScales = Object.keys(this.config.datasets).filter(item => !this.hideDatasets[item]).map((item) => {
        return this.config.datasets[item].scale || 'y'
      })

      if (visibleScales.length > 0) {
        return [...new Set(visibleScales)]
      } else {
        return ['y']
      }
    },
    scalesOptions() {
      const scalesOptions = {}

      // x scale
      scalesOptions['x'] = {
        grid: {
          color: this.gridColor
        }
      }

      // y scales
      if (this.scales.length > 0) {
        for (var i = 0; i < this.scales.length; i += 1) {
          scalesOptions[this.scales[i]] = {
            beginAtZero: false,
            display: true,
            position: (i === 0) ? 'left' : 'right',
            grid: {
              color: this.gridColor
            },
            title: {
              display: (!!this.config.scales[this.scales[i]].label),
              text: this.config.scales[this.scales[i]].label,
              padding: 0
            }
          }
        }
      } else {
        scalesOptions['y'] = {
          beginAtZero: false,
          grid: {
            color: this.gridColor
          }
        }
      }

      return scalesOptions
    },
    datasets () {
      return (typeof this.config.datasets === 'object') ? Object.keys(this.config.datasets).filter(item => !this.hideDatasets[item]).map((item) => {
        return {
          label: this.config.datasets[item].label, 
          fill: false,
          backgroundColor: this.datasetsColors[item], 
          borderColor: this.datasetsColors[item], 
          borderWidth: 2,
          pointRadius: 0,
          spanGaps: true,
          data: this.data[item],
          yAxisID: this.config.datasets[item].scale || 'y'
        }
      }) : []
    },
    lineData () {
      return {
        labels: this.labels,
        datasets: this.datasets
      }
    },
  },
  created () {
    this.loadTemperatures()
    .then(res => {
      if (res.error) {
        this.$store.commit('alert', {
          color: 'error',
          text: res.error.code
        })
      } else {
        this.buildChart()
      }
    })
  },
  watch: {
    date: function () {
      this.updateChart()
    },
    clock() {
      if (this.date == this.now) {
        this.updateChart()
      }
    }
  },
  beforeMount() {
    if (!this.controllerById) {
      this.$router.push('/controllers')
    }
  },
  mounted() {
    this.setPageTitle(this.locales['stats']);
  },
  beforeDestroy() {
    this.setPageTitle(undefined);
  },
  methods: {
    ...mapMutations(['setPageTitle']),
    ...mapActions('statistics', ['getAll']),
    loadTemperatures () {
      return this.getAll({ controllerId: this.controllerId, date: this.date })
    },
    updateChart() {
      this.loadTemperatures()
      .then(data => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
        } else {
          this.chart.data.labels = this.labels
          this.chart.data.datasets = this.datasets
          
          this.chart.update();
        }
      })
    },
    buildChart () {
      if (!this.config.scales) { this.$store.commit('snack/open', { color: "error", text: 'Brak config.scales' }) }
      if (!this.config.datasets) { this.$store.commit('snack/open', { color: "error", text: 'Brak config.datasets' }) }

      const ctx = document.getElementById('tempChart');
      const opts = {
          type: 'line',
          data: {
            labels: this.labels,
            datasets: this.datasets
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              legend: {
                display: false
              },
              zoom: {
                zoom: {
                  wheel: {
                    enabled: true,
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'x'
                },
                pan: {
                  enabled: true,
                  mode: 'x',
                }
              }
            },
            /*scales: {
              y1: {
                beginAtZero: false,
                display: true,
                position: 'left',
                grid: {
                  color: this.gridColor
                }
              },
              y2: {
                beginAtZero: false,
                display: true,
                position: 'right',
                grid: {
                  color: this.gridColor
                }
              },
              x: {
                grid: {
                  color: this.gridColor
                }
              }
            } */
          }
      }

      // scales
      

      opts.options.scales = this.scalesOptions

      this.chart = new Chart(ctx, opts);
    },
    toggleHide (key) {
      this.$set(this.hideDatasets, key, !this.hideDatasets[key])
      this.chart.data.datasets = this.datasets
      this.chart.options.scales = this.scalesOptions
      this.chart.update()
    },
    nextDate () {
      const current = new Date(this.date)
      let prev = new Date(current)
      prev.setDate(current.getDate() + 1)
      this.date = prev.toISOString().substr(0, 10)
    },
    prevDate () {
      const current = new Date(this.date)
      let prev = new Date(current)
      prev.setDate(current.getDate() - 1)
      this.date = prev.toISOString().substr(0, 10)
    }
  }
}
// https://jsfiddle.net/24gszm9h/4/

// https://newbedev.com/how-can-i-create-a-horizontal-scrolling-chart-js-line-chart-with-a-locked-y-axis
// http://jsfiddle.net/mbhavfwm/
// https://stackoverflow.com/questions/35854244/how-can-i-create-a-horizontal-scrolling-chart-js-line-chart-with-a-locked-y-axis

// https://jsfiddle.net/EmmaLouise/eb1aqpx8/3/
// https://jsfiddle.net/gh7qb4ud/1/
</script>

<style>
  .chart-container {
    width: 100%;
    height: 65vh;
  }
  .inactive {
    opacity: 0.5;
  }
</style>