//import { getToken } from "./getToken";
//import { badResponse } from "./badResponse";
import { apiUrl, getToken, badResponse } from "./api";

export default {
  /*get (id) {
    return fetch('https://www.radiorekord.pl/tracklist/get_user.php?id=' + id + '&t=' + new Date().getTime())
    .then(response => response.json())
  },*/
  getAll(controllerId, pathpart) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('token', getToken());
    return fetch(apiUrl() + '/sterownik/users' + ((pathpart) ? '/' + pathpart : '') + '?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  create(email, password, password2) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('password2', password2);
    return fetch(apiUrl() + '/uzytkownik/rejestracja?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  update(controllerId, email, permission) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('email', email);
    formData.append('permission', permission);
    formData.append('token', getToken());
    return fetch(apiUrl() + '/sterownik/uprawnienia/edytuj?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  deleteProfile() {
    const formData = new FormData();
    formData.append('token', getToken());
    return fetch(apiUrl() + '/uzytkownik/usun?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  login(email, password, lang) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('lang', lang);
    
    return fetch(apiUrl() + '/uzytkownik/logowanie?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  remind(email) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('token', getToken());

    return fetch(apiUrl() + '/uzytkownik/przypomnienie-hasla?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  confirm(token) {
    const formData = new FormData();
    formData.append('token', token);

    return fetch(apiUrl() + '/uzytkownik/rejestracja/potwierdzenie?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  newpass(password, password2, token) {
    const formData = new FormData();
    formData.append('password', password);
    formData.append('password2', password2);
    formData.append('token', token);

    return fetch(apiUrl() + '/uzytkownik/przypomnienie-hasla-2?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  changepass(password, password2, passwordOld) {
    const formData = new FormData();
    formData.append('passwordOld', passwordOld);
    formData.append('password', password);
    formData.append('password2', password2);
    formData.append('token', getToken());

    return fetch(apiUrl() + '/uzytkownik/zmiana-hasla?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  logout() {
    const profile = this.restore();
    profile.token = undefined;
    this.store(profile);
    return profile;
  },
  restore() {
    const profile = localStorage.getItem('profile');
    if (profile) {
      return JSON.parse(profile)
    } else {
      return {}
    }
  },
  store(profileData) {
    localStorage.setItem('profile', JSON.stringify(profileData));
  },
  sendMessagesToken(messagesToken, oldToken) {
    const formData = new FormData();
    formData.append('tokenFCM', messagesToken);
    formData.append('tokenFCMold', oldToken);
    formData.append('token', getToken());

    return fetch(apiUrl() + '/uzytkownik/powiadomienia/push?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  getNotificationsMail() {
    const formData = new FormData();
    formData.append('token', getToken());

    return fetch(apiUrl() + '/uzytkownik/ustawienia?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  postNotificationsMail(emailNotification) {
    const formData = new FormData();
    formData.append('email', emailNotification);
    formData.append('token', getToken());

    return fetch(apiUrl() + '/uzytkownik/powiadomienia/email?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  }
}
//https://jasonwatmore.com/post/2018/07/14/vue-vuex-user-registration-and-login-tutorial-example
//https://en.wikipedia.org/wiki/JSON_Web_Token
//https://medium.com/js-dojo/how-to-build-a-spa-using-vue-js-vuex-vuetify-and-firebase-adding-authentication-with-firebase-d9932d1e4365