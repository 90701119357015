<template>
    <v-dialog v-model="dialog" max-width="300px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          color="primary"
        >
          <v-icon left>language</v-icon>
          <span>{{ locale.id }}</span>
        </v-btn>
      </template>
      <v-card class="rounded-lg">
        <v-card-title>
          <span>{{ locales['choose-language'] }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
            class="bar-last-el"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0">
          <v-list width="100%" class="nobg py-0">
            <v-list-item-group>
              <v-list-item class="px-6" v-for="(item, index) in localesList" :key="index" @click="setLang(item.id)" :class="{'v-list-item--active': item.active === '1'}">
                <v-list-item-icon class="text-uppercase">
                  {{ item.id }}
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    dialog: false,
    langs: [
      { key: 'en', name: 'English' },
      { key: 'pl', name: 'Polski' }
    ]
  }),
  computed: {
    ...mapGetters('locales', ['locales', 'localesList', 'locale']),
  },
  methods: {
    ...mapActions('locales', ['loadLocales']),
    setLang(lang) {
      // this.$i18n.locale = lang;
      this.loadLocales(lang);
      this.dialog = false;
    }
  }
}
</script>