import locales from '../../api/locales'

const state = () => ({
  all: {},
})

const getters = {
  locales: (state) => {
    return state.all
  },
  translate: (state) => (name) => {
    return (state.all[name]) ? state.all[name] : ''
  },
  localesList: (state) => {
    return state.all.langs
  },
  locale: (state) => {
    return (state.all.langs) ? state.all.langs.find(o => o.active === '1') : {}
  }
}

const mutations = {
  setAll: (state, data) => {
    state.all = data
  },
}

const actions = {
  loadLocales({commit}, locale) {
    const restored = (!locale) ? locales.restore() : null
    if (restored) {
      return new Promise((resolve) => {
        commit('setAll', restored)
        resolve(restored)
      })
    } else {
      return locales.get(locale)
      .then(data => {
        if (!data.error) {
          commit('setAll', data)
          locales.store(data)
        }
        return data
      })
    }
  },
  /* fetchLocales({ commit }, locale) {
    return locales.get(locale)
    .then(data => {
      commit('setAll', {
        data: data
      });
      locales.store(data);
      return true;
    })
  },
  restoreLocales({commit}) {
    return new Promise((resolve) => {
      commit('restore', locales.restore());
      resolve();
    })
  }, */
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}