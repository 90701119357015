<template>
  <div v-if="editMode">xxx</div>
  <div v-else>

    <v-btn @click="toggleEdit" text icon class="op-68">
      <v-icon>sync</v-icon>
    </v-btn>
  </div>
  
</template>

<script>
export default {
  props: ['refreshModule'],
  data: () => ({
    editMode: false
  }),
  methods: {
    toggleEdit() {
      this.editMode = true
      /*if (this.refreshModule == 'controllers') {
        this.$store.dispatch('controllers/getAll')
        .then(() => {
          setTimeout(() => { this.loading = false }, 400)
        })
      } else {
        this.$store.dispatch('parameters/getAll')
        .then(() => {
          setTimeout(() => { this.loading = false }, 400)
        })
      }*/
    }
  }
}
</script>