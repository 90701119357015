//import { getToken } from "./getToken";
//import { badResponse } from "./badResponse";
import { apiUrl, getToken, badResponse } from "./api";

export default {
  getDefault(controllerId, paramName) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('token', getToken());
    return fetch(apiUrl() + '/sterownik/' + paramName + '/harmonogram?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(() => badResponse());
  },
  get(controllerId, paramName, id) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('token', getToken());
    return fetch(apiUrl() + '/sterownik/' + paramName + '/harmonogram/' + id + '?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(() => badResponse());
  },
  update(controllerId, paramName, schedule, id, name) {
    const formData = new FormData()
    formData.append('controllerId', controllerId)
    formData.append('id', id)
    formData.append('name', name)
    formData.append('schedule', JSON.stringify(schedule))
    formData.append('token', getToken())
    
    return fetch(apiUrl() + '/sterownik/' + paramName + '/harmonogram/edytuj?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(() => badResponse());
  },
}
