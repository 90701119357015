<template>
	<v-container
		class="fill-height flex-column justify-md-center"
		fluid
	>
		<div class="flex-grow-1 flex-md-grow-0 d-flex align-center w-100">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="4"
          lg="3"
        >
          <v-carousel
            cycle
            height="350"
            hide-delimiter-background
            hide-delimiters
            :show-arrows=false
            :dark="$vuetify.theme.dark"
            :light="!$vuetify.theme.dark"
          >
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <div>
                  <div class="d-flex align-end justify-center mb-10" v-html="slide.icon"></div>
                  <div class="text-h5 text-center mb-3">{{ translate('login-title') }}</div>
                  <div class="text-body-2 text-center px-10">{{ translate('login-content') }}</div>
                </div>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </div>
    <div class="flex-shrink-1 h-action-column w-100">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <v-btn to="/login" x-large rounded color="primary" class="w-100 mb-2">{{ translate('login') }}</v-btn>
          <v-btn to="/register" x-large rounded outlined color="primary" class="w-100">{{ translate('create-account') }}</v-btn>
          <div class="text-center mt-3 text-body-2">
            <a href="/privacy_policy_pl.html" target="_blank" class="offf">{{ translate('privacy-policy') }}</a>
          </div>
        </v-col>
      </v-row>
    </div>
	</v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        langs: ['pl','en'],
        slides: [
          {
            icon: '<svg class="onboarding-img embed" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 10.896 11.248"><defs><linearGradient id="a" x1=".502" y1="1.024" x2=".502" y2=".016" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#068dce"/><stop offset="1" stop-color="#2bb1e6"/></linearGradient><linearGradient id="b" x1="-43.473" y1="-558.949" x2="-43.473" y2="-556.311" xlink:href="#a"/><linearGradient id="c" x1="-36.963" y1="-532.639" x2="-36.963" y2="-529.416" xlink:href="#a"/><linearGradient id="d" x1="-45.182" y1="-561.147" x2="-45.182" y2="-558.52" xlink:href="#a"/></defs><path data-name="Path 1" d="M8.68 9.14V6.53a.67.67 0 1 0-1.33 0v2.61a1.13 1.13 0 0 0-.45.86 1.12 1.12 0 0 0 2.23 0 1.13 1.13 0 0 0-.45-.86Z" fill="url(#a)" transform="translate(-2.551 -2.042)"/><path class="negativ" data-name="Path 2" d="M.389 5.738a.39.39 0 0 1-.27-.67l5-4.89a.62.62 0 0 1 .87 0l4.79 4.78a.392.392 0 0 1-.55.56L5.519.848l-4.85 4.78a.43.43 0 0 1-.28.11Z" fill="#fff"/><path data-name="Path 3" d="M8.249 11.248h-5.45a1.25 1.25 0 0 1-1.25-1.29v-3.83a.39.39 0 0 1 .39-.39.4.4 0 0 1 .39.39v3.83a.47.47 0 0 0 .47.47h5.45a.47.47 0 0 0 .47-.47v-3.83a.4.4 0 0 1 .39-.39.39.39 0 0 1 .39.39v3.83a1.25 1.25 0 0 1-1.25 1.29Z" fill="#26ade3"/><path class="negativ" data-name="Path 4" d="M5.669 7.538v-1.82a.205.205 0 0 0-.41 0v1.82a.505.505 0 1 0 .41 0Z" fill="#fff"/><path data-name="Path 5" d="M6.76 6.85a.17.17 0 0 1 .17.15.18.18 0 0 1-.17.17H6.2A.18.18 0 0 1 6 7a.17.17 0 0 1 .17-.17Z" fill="url(#b)" transform="translate(-2.551 -2.042)"/><path data-name="Path 6" d="M6.76 7.64a.18.18 0 0 1 .17.18.18.18 0 0 1-.17.18H6a.18.18 0 0 1-.18-.17.18.18 0 0 1 .18-.19Z" fill="url(#c)" transform="translate(-2.551 -2.042)"/><path data-name="Path 7" d="M6.76 8.44a.18.18 0 0 1 .17.17.17.17 0 0 1-.17.17H6.2a.17.17 0 0 1-.2-.17.18.18 0 0 1 .17-.17Z" fill="url(#d)" transform="translate(-2.551 -2.042)"/></svg>',
          }
        ],
      }
    },
    computed: {
      ...mapGetters('locales', ['translate']),
    },
  }
</script>

<style>
  .onboarding-img {
    width: 110px;
    height: auto;
  }
  .onboarding-space {
    height: 140px;
  }
</style>

<!-- slides: [
          {
            icon: '<svg class="onboarding-img embed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.585 13.958"><path d="M7.83 3.084s.24-.744-.327-1.274c-.555-.518-.24-.951.177-1.265 0 0-.403.478.209.927.512.374.312 1.083-.06 1.612M8.456 2.74s.381-.816.009-1.26c-.371-.446-.45-.649-.416-.922C8.083.285 8.566 0 8.566 0s-.262.23-.11.575c.153.346.812.376.844.963.03.553-.676 1.067-.844 1.202M5.996 10.903a.203.203 0 11-.407 0V8.086a.203.203 0 11.407 0z" fill="#ffffff"/><path d="M6.28 11.212a.488.488 0 10-.975 0 .488.488 0 00.975 0" fill="#ffffff"/><path d="M5.792 12.194a.98.98 0 01-.658-1.708V7.753a.66.66 0 011.317 0v2.733a.98.98 0 01-.659 1.708z" fill="none" stroke="#ffffff" stroke-width=".423336" stroke-miterlimit="10"/><path d="M5.792 3.207l-.346.34L.86 8.05 0 8.897h1.535v5.061H10.05V8.897h1.535l-.862-.847-1.507-1.48V3.251h-2.13v1.226l-.948-.931zm2.93.539v3.03l1.655 1.627h-.821v5.062H2.029V8.403h-.821l4.584-4.504 1.787 1.756v-1.91z" fill="#ffffff"/></svg>',
          },
          {
            icon: '<svg class="onboarding-img embed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.9 8.923"><path d="M6.061 5.177a.902.902 0 00-.708.345.898.898 0 001.413 1.105.897.897 0 00-.705-1.45m-.003 2.288a1.393 1.393 0 01-.167-2.771A1.393 1.393 0 017.155 6.93a1.39 1.39 0 01-1.098.534M2.973 2.654l-.44-.438.349-.35.44.438zM6.423 1.5h-.494V.876h.495zM1.5 6.009H.877v-.494h.621z" fill="#ffffff"/><path d="M9.978 2.078l.012.012.07-.09zm.417 5.913l.5.262a5.456 5.456 0 00.511-2.302c0-1.368-.507-2.67-1.43-3.679l-.385.414c.809.9 1.25 2.05 1.25 3.265 0 .714-.15 1.399-.446 2.04m.713.932L9.733 8.2l.115-.219c.332-.632.5-1.315.5-2.031 0-1.163-.451-2.26-1.271-3.09l-.166-.169.716-.769A5.4 5.4 0 005.95.493 5.463 5.463 0 00.494 5.952c0 .877.215 1.75.621 2.524l-.437.23A5.945 5.945 0 010 5.95 5.957 5.957 0 015.95 0c1.631 0 3.153.65 4.287 1.828l.03.03A5.924 5.924 0 0111.9 5.951c0 .956-.234 1.909-.678 2.753z" fill="#ffffff"/><path d="M6.676 5.319a.247.247 0 01-.213-.371l1.151-1.98a.247.247 0 11.427.248L6.89 5.196a.246.246 0 01-.214.123M5.062 8.093a.247.247 0 01-.214-.37l.448-.771a.247.247 0 11.427.248l-.448.77a.247.247 0 01-.213.123" fill="#ffffff"/></svg>',
          }
        ] -->