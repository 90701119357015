<template>
  <v-card-text class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column flex-grow-1 justify-center">
      <div v-if="config.prompt" class="text-center pb-4 op-68">{{ config.prompt }}</div>
      <v-list :dense="longList" rounded class="nobg option-list">
        <v-list-item-group v-model="value_set" mandatory active-class="listactive" color="primary" class="text-center">
          <v-list-item
            v-for="(item, i) in config.options"
            :key="i"
            :value="i.toString()"
          >
            <v-list-item-content>
              <v-list-item-title class="option" v-text="(config.unit) ? item + ' ' + config.unit : item">x</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="false">
              <v-icon>edit</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <v-card-actions class="flex-shrink-1 pb-5 pb-sm-0 px-3 px-sm-0" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
      <v-btn x-large rounded text exact :color="(isMobile) ? 'primary' : 'secondary'" @click="close" class="mr-1">{{ locales['cancel'] }}</v-btn>
      <v-btn x-large rounded :text="!isMobile" color="primary" @click="save" :loading="loading" class="px-16 px-md-6">{{ locales['save'] }}</v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
import {mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  props: ['paramName'],
  data: () => ({
		valid: true,
    value_set: undefined,
    loading: false
	}),
	computed: {
    ...mapGetters('parameters', ['currentDevice', 'editDialog', 'paramValue', 'paramConfig']),
    ...mapGetters(['isMobile']),
    // ...mapGetters('profile', ['locale']),
    ...mapGetters('locales', ['locales']),
    config() {
      return (this.paramName) ? this.paramConfig(this.paramName) : {}
    },
    longList() {
      return Object.keys(this.config.options).length > 8 ? true : false
    }
  },
  watch: {
    paramName() {
      this.setup();
    }
  },
  created() {
    this.setup();
  },
	methods: {
    ...mapMutations('parameters', ['editDialogClose', 'setParameter']),
    ...mapActions('parameters', ['update']),
    close () {
      this.loading = false;
      this.editDialogClose();
    },
    setup() {
      this.value_set = this.paramValue(this.paramName).toString();
    },
    save () {
      this.loading = true;
      this.update({
        paramName: this.paramName,
        paramValue: this.value_set
      })
      .then(data => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
          this.loading = false;
        } else {
          if (data[this.paramName]) {
            this.$store.commit('snack/open', { color: "success", timeout: 700, text: this.locales['changes-saved'] })
            //this.setParameter({name: this.paramName, value: this.value_set});
            this.setParameter({name: this.paramName, value: data[this.paramName]});
            this.close();
          } else {
            this.$store.commit('snack/open', { color: "error", timeout: 5000, text: this.locales['no-value'] + ': ' + this.paramName });
            this.loading = false;
          }
        }
      });
    }
	}
}
</script>

<style scoped>
.option-list .v-list-item {
  border: 2px solid transparent;
}
.option-list .v-list-item.listactive {
  border: 2px solid #2196f3;
}
.option-list .listactive.v-list-item--active::before {
  opacity: 0;
}
.v-list-item__title {
  font-size: 1.25rem;
}
.v-list:not(.v-list--dense) .listactive .v-list-item__title {
  font-weight: 700;
  font-size: 1.5rem;
}

.option-list .v-list-item--dense, 
.v-list--dense.option-list .v-list-item {
  min-height: 32px;
}
.option-list .v-list-item--dense .v-list-item__content, 
.v-list--dense.option-list .v-list-item .v-list-item__content {
  padding: 4px 0;
}

</style>