import schedules from '../../api/schedules'

const state = () => ({
  all: {},
  loading: false
})

const getters = {
  getAll: (state) => (name) => {
    return (state.all[name]) ? state.all[name] : {}
  },
  loading(state) {
    return state.loading
  }
}

const mutations = {
  setAll: (state, data) => {
    state.all = data
  },
  setLoading: (state, data) => {
    state.loading = data
  },
}

const actions = {
  getDefault({commit}, {controllerId, paramName}) {
    commit('setLoading', true);
    return schedules.getDefault(controllerId, paramName)
    .then(data => {
      commit('setAll', data);
      commit('setLoading', false);
      return data;
    })
  },
  update({commit}, {controllerId, paramName, schedule, id, name}) {
    commit('setLoading', true);
    return schedules.update(controllerId, paramName, schedule, id, name)
    .then(data => {
      //if (!data.error) { dispatch('getAll'); }
      commit('setLoading', false);
      return data;
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}