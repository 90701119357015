<template>
  <v-card-text v-if="dataComplete" class="d-flex flex-column flex-grow-1">
    <div class="py-16 d-flex flex-column flex-grow-1 justify-center">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        autocomplete="off"
        class="w-100"
      >
        <v-text-field
          v-model="value_set" 
          required 
          :rules="[rules.required]"
          :error-messages="errorMessages"
          :label="config.prompt ? config.prompt : config.label"
          type="text"
          outlined
        ></v-text-field>
      </v-form>
    </div>
    <v-card-actions v-if="config.label" class="pb-5 pb-sm-0 px-3 px-sm-0" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
      <v-btn x-large rounded text exact :color="(isMobile) ? 'primary' : 'secondary'" @click="close" class="mr-1">{{ locales['cancel'] }}</v-btn>
      <v-btn x-large rounded :text="!isMobile" color="primary" @click="save" :loading="loading" class="px-16 px-md-6">{{ locales['save'] }}</v-btn>
    </v-card-actions>
  </v-card-text>
  <v-card-text v-else class="d-flex flex-column flex-grow-1 px-0 justify-center center h-min-dialog">
    <v-progress-circular
      :size="120"
      :width="7"
      color="primary"
      indeterminate
      class="install-progress"
    ></v-progress-circular>
  </v-card-text>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['paramName'],
  data: () => ({
		valid: true,
    value_set: undefined,
    empty: false,
    loading: false,
    rules: {
      required: value => !!value || 'Wypełnij to pole'
    },
	}),
	computed: {
    ...mapGetters('parameters', ['editDialog', 'paramValue', 'paramConfig']),
    ...mapGetters(['isMobile']),
    ...mapGetters('locales', ['locales']),
    config() {
      return (this.paramName) ? this.paramConfig(this.paramName) : {}
    },
    minValue() {
      return (this.config.min) ? parseFloat(this.config.min) : 0
    },
    maxValue() {
      return (this.config.max) ? parseFloat(this.config.max) : 255
    },
    valueValid() {
      return (this.value_set !== undefined && this.value_set.length >= this.minValue && this.value_set.length <= this.maxValue)
    },
    dataComplete() {
      return (typeof this.minValue !== 'undefined' && typeof this.maxValue !== 'undefined' && typeof this.value_set !== 'undefined')
    },
    errorMessages() {
      let message = null
      
      if (this.value_set !== undefined && this.value_set.length > 0) {
        if (this.value_set.length < this.minValue) {
          message = 'Min. ' + this.minValue + ' znaków'
        } else if (this.value_set.length > this.maxValue) {
          message = 'Max ' + this.maxValue + ' znaków'
        } else if (this.empty) {
          message = 'Wypełnij to pole'
        }
      }

      return message
    }
  },
  watch: {
    paramName() {
      this.setup()
    }
  },
  created() {
    this.setup()
    console.log(this.rules)
  },
	methods: {
    ...mapMutations('parameters', ['editDialogClose', 'setParameter']),
    ...mapActions('parameters', ['update']),
    setup () {
      this.value_set = this.paramValue(this.paramName)
    },
    close () {
      this.loading = false;
      this.editDialogClose();
    },
    save () {
      if (this.$refs.form.validate() && !this.errorMessages) {
        this.loading = true;
        this.update({
          paramName: this.paramName,
          paramValue: this.value_set
        })
        .then(data => {
          if (data.error) {
            this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
            this.loading = false;
          } else {
            if (data[this.paramName]) {
              this.$store.commit('snack/open', { color: "success", timeout: 700, text: this.locales['changes-saved'] })
              //this.setParameter({name: this.paramName, value: this.value_set});
              this.setParameter({name: this.paramName, value: data[this.paramName]})
              this.close()
            } else {
              this.$store.commit('snack/open', { color: "error", timeout: 5000, text: this.locales['no-value'] + ': ' + this.paramName })
              this.loading = false
            }
          }
        })

      }
    }
	}
}
</script>
