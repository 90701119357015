import { apiUrl, getToken, badResponse } from "./api";

export default {
  get(locale) {
    const token = getToken()
    const formData = new FormData()
    if (locale) {
      formData.append('lang', locale)
    }
    if (token) {
      formData.append('token', token)
    }
    
    return fetch(apiUrl() + '/slownik/jezyk?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse())
  },
  restore() {
    const localesData = localStorage.getItem('locales');
    if (localesData) {
      return JSON.parse(localesData)
    } else {
      return null
    }
  },
  store(data) {
    localStorage.setItem('locales', JSON.stringify(data));
  }
}