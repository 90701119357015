<template>
	<v-container
		class="fill-height flex-column justify-lg-center"
		fluid
	>
		<div class="flex-grow-1 d-flex align-center w-100">
      <v-row
        align="center"
        justify="center"
        class="flex-grow-1"
      >
        <v-col
          cols="10"
          sm="8"
          md="4"
        >
          <transition name="fade" mode="out-in">
						<div v-if="success" key="1" class="text-center">
							<div class="text-h5 text--primary mb-8">{{ locales['success'] }}</div>
							<p>{{ locales['active-account'] }}</p>
						</div>
            <div v-else-if="error" key="2" class="text-center">
							<div class="text-h5 text--primary mb-8">{{ locales['error'] }}</div>
							<p>{{ errorMessage }}</p>
						</div>
            <v-progress-circular
              v-else 
              key="3"
              :size="120"
              :width="7"
              color="primary"
              indeterminate
              class="install-progress"
            ></v-progress-circular>
          </transition>
        </v-col>
      </v-row>
    </div>
    <div class="flex-shrink-1 h-action-column d-flex flex-column">
      <v-btn v-if="success || errorCode == 10" x-large rounded color="primary" to="/login" class="w-btn">{{ locales['login'] }}</v-btn>
    </div>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['token'],
  data: () => ({
    success: false,
    errorMessage: undefined,
    errorCode: undefined
	}),
	computed: {
		...mapGetters('profile', ['loading']),
    ...mapGetters('locales', ['locales']),
		error() {
			return (this.errorMessage) ? true : false
		}
  },
  mounted () {
    if (!this.token) {
      this.errorMessage = this.locales['invalid-link']
    } else {
      this.confirmUser()
    }
  },
	methods: {
		...mapActions('profile', ['confirm']),
		confirmUser() {
      this.confirm({
        token: this.token
      })
      .then(data => {
        if (data.error) {
          this.errorMessage = this.locales['error-' + data.error.code] || ''
          this.errorCode = data.error.code
        } else {
          this.success = true
        }
      });
			
		}
	}
}
</script>
