import { apiUrl, getToken, badResponse } from "./api";

export default {
  getAll(controllerId, date, pathpart) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('token', getToken());
    formData.append('date', date);
    
    return fetch(apiUrl() + '/sterownik/statistics' + ((pathpart) ? '/' + pathpart : '') + '?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
}