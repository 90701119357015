import controllers from '../../api/controllers'

const state = () => ({
  all: [],
  loading: false
})

const getters = {
  all(state) {
    return state.all
  },
  byId: (state) => (id) => {
    return state.all.find(el => el.controllerId == id)
  },
  loading(state) {
    return state.loading
  }
}

const mutations = {
  setAll: (state, data) => {
    state.all = data
  },
  setLoading: (state, data) => {
    state.loading = data
  },
  restore: (state, data) => {
    state.all = data.all
  }
}

const actions = {
  getAll({commit, state}) {
    commit('setLoading', true, { root: true });
    return controllers.getAll()
    .then(data => {
      commit('setAll', data);
      commit('setLoading', false, { root: true });
      controllers.store(state);
      return data;
    })
  },
  create({commit, dispatch}, {controllerId, displayName, demo}) {
    commit('setLoading', true);
    return controllers.create(controllerId, displayName, demo)
    .then(data => {
      if (!data.error) { dispatch('getAll'); }
      commit('setLoading', false);
      return data;
    })
  },
  update({commit, dispatch}, {controllerId, displayName}) {
    commit('setLoading', true);
    return controllers.update(controllerId, displayName)
    .then(data => {
      if (!data.error) { dispatch('getAll'); }
      commit('setLoading', false);
      return data;
    })
  },
  restore({commit}) {
    return new Promise((resolve) => {
      commit('restore', controllers.restore());
      resolve();
    })
  },
  remove({commit}, {controllerId}) {
    commit('setLoading', true);
    return controllers.remove(controllerId)
    .then(data => {
      // if (!data.error) { dispatch('getAll'); }
      commit('setLoading', false);
      return data;
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}