<template>
	<v-container
		class="fill-height flex-column justify-md-center"
		fluid
	>
		<div class="flex-grow-1 flex-md-grow-0 d-flex align-center w-100">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <transition name="fade" mode="out-in">
						<div v-if="success" key="1">
							<div class="text-h5 text--primary mb-8">{{ locales['account-created'] }}</div>
							<p>{{ locales['email-sent'] }}</p>
						</div>
						<div v-else key="2">
              <div class="text-h5 text--primary mb-8">{{ locales['create-account'] }}</div>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                autocomplete="off"
              >
                <v-text-field
                  v-model="email" 
                  required 
                  :rules="emailRules"
                  label="E-mail"
                  name="email"
                  prepend-inner-icon="alternate_email"
                  type="email"
                  :loading="loading"
                ></v-text-field>
                <v-text-field 
                  prepend-inner-icon="vpn_key"
                  label="Nowe hasło" 
                  :type="passType ? 'text' : 'password'" 
                  v-model="passwd" 
                  :rules="[rules.required, rules.min]"
                  :append-icon="passType ? 'visibility' : 'visibility_off'" 
                  @click:append="passType = !passType"
                  required
                  :loading="loading"
                  counter
                ></v-text-field>
                <v-text-field 
                  prepend-inner-icon="vpn_key"
                  label="Powtórz hasło" 
                  :type="pass2Type ? 'text' : 'password'" 
                  v-model="passwd2" 
                  :rules="[rules.required, rules.min, passwordsMatch]"
                  :append-icon="pass2Type ? 'visibility' : 'visibility_off'" 
                  @click:append="pass2Type = !pass2Type"
                  required
                  :loading="loading"
                  counter
                ></v-text-field>
              </v-form>
						</div>
          </transition>
        </v-col>
      </v-row>
    </div>
    <div class="flex-shrink-1 h-action-column w-100 pt-md-12">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <v-btn v-if="!success" x-large rounded color="primary" @click="registerUser" :loading="loading" class="w-100 mb-2">{{ locales['create-account'] }}</v-btn>
          <v-btn v-if="!success" x-large rounded text color="primary" to="/" class="w-100">{{ locales['cancel'] }}</v-btn>
          <v-btn v-if="success" x-large rounded color="primary" to="/login" class="w-100">{{ locales['login'] }}</v-btn>
        </v-col>
      </v-row>
    </div>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	data: () => ({
		valid: true,
		email: undefined,
		emailRules: [
			v => !!v || 'Podaj adres e-mail',
			v => /.+@.+\..+/.test(v) || 'E-mail jest nieprawidłowy',
		],
		passwd: '',
    passType: false,
    passwd2: '',
		pass2Type: false,
    rules: {
      required: value => !!value || 'Podaj hasło',
      min: value => value.length >= 8 || 'Min 8 znaków'
    },
    success: false
	}),
	computed: {
		...mapGetters('profile', ['loading']),
    ...mapGetters('locales', ['locales']),
		passwordsMatch() {
      return () => (this.passwd === this.passwd2) || 'Hasła muszą być jednakowe'
    },
  },
	methods: {
		...mapActions('profile', ['register']),
		registerUser() {
			if (this.$refs.form.validate()) {
				this.register({
					email: this.email,
          password: this.passwd,
          password2: this.passwd2
				})
				.then(data => {
					if (data.error) {
						this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
					} else {
						this.success = true;
					}
				});
			}
		}
	}
}
</script>
