//import { getToken } from "./getToken";
//import { badResponse } from "./badResponse";
import { apiUrl, getToken, badResponse } from "./api";

export default {
  getMenu(name, controllerId, password) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('token', getToken());

    if (typeof password !== 'undefined' && password) {
      formData.append('password', password);
    }

    return fetch(apiUrl() + '/menu/' + name + '?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(() => badResponse());
  },
}
