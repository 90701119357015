import menu from '../../api/menu'

const state = () => ({
  all: {
    sterownik: {params:[]},
    'menu-lewe': {params:[]},
    uzytkownik: {params:[]},
    serwis: {params:[]}
  },
  loading: false
})

const getters = {
  getMenu: (state) => (name) => {
    return (state.all[name]) ? state.all[name] : {}
  },
  menuLewe(state) {
    return state.all['menu-lewe']
  },
  loading(state) {
    return state.loading
  }
}

const mutations = {
  setMenu: (state, {name, data}) => {
    state.all[name] = data
  },
  setLoading: (state, data) => {
    state.loading = data
  },
}

const actions = {
  fetchMenu({commit}, {name, controllerId, password}) {
    return menu.getMenu(name, controllerId, password)
    .then(data => {
      commit('setMenu', {
        name: name, 
        data: data
      });
      return data;
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
