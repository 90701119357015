<template>
  <v-container
		v-if="loading"
    class="flex-column justify-center fill-height pt-0 app-container center"
		fluidx
	>
    <v-progress-circular
      :size="120"
      :width="7"
      color="primary"
      indeterminate
      class="install-progress"
    ></v-progress-circular>
  </v-container>
  <v-container
		v-else-if="loadError"
    class="flex-column justify-center fill-height pt-0 app-container center"
		fluidx
	>
    <v-alert class="r-16 py-5">
      <div class="text-center px-5 pb-3">{{ locales['load-error'] }}</div>
      <div class="text-center">
        <v-btn rounded outlined color="primary" @click="load(1)">Odśwież</v-btn>
      </div>
    </v-alert>
  </v-container>
  <v-container
		v-else
    class="flex-columnx px-0 pt-0"
		fluid
	>
      <div v-if="dataCount > 0" class="w-100">
        <v-list two-line width="100%" class="nobg pt-0">
          <v-list-item-group>
            <v-divider key="01"></v-divider>
            <template v-for="(item, i) in all">
            <v-list-item class="pl-4 pr-6" :key="i">
              <v-list-item-avatar>
                <v-icon :color="colors[item.type]" v-text="icons[item.type]"></v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="lh-125">
                {{ item.message }}
              </v-list-item-content>
              <v-list-item-action-text class="text-right text--secondary pl-2 lh-125 dat" v-html="dateFormat(item.date)"></v-list-item-action-text>
            </v-list-item>
            <v-divider :key="i+'d'"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <div class="px-2" v-if="!noMoreData">
          <v-btn text color="primary" @click="load(startIndex)">{{ locales['more'] }}</v-btn>
        </div>
      </div>
      <div v-else-if="!loading">
        <v-divider></v-divider>
        <div class="text-h6 text-center pt-8 px-5">{{ locales['no-alarms'] }}</div>
      </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: ['controllerId', 'pathpart'],
  data () {
    return {
      noMoreData: false,
      loadError: false
    }
  },
  computed: {
    ...mapGetters('alarms', ['all','icons','colors']),
    ...mapGetters('controllers', ['byId']),
    ...mapGetters('locales', ['locales']),
    ...mapGetters(['loading','clock']),
    controllerById() {
      return this.byId(this.controllerId)
    },
    dataCount() {
      return this.all.length
    },
    startIndex() {
      return this.dataCount + 1;
    }
  },
  beforeMount() {
    if (!this.controllerById) {
      this.$router.push('/controllers')
    }
  },
  mounted() {
    this.setPageTitle(this.locales['alarms']);
    this.load(1);
  },
  beforeDestroy() {
    this.setPageTitle(undefined);
  },
  watch: {
    clock() {
      this.load(1)
    },
    pathpart() {
      this.load(1)
    }
  },
  methods: {
    ...mapActions('alarms', ['getAll']),
    ...mapMutations(['setPageTitle']),
    load(startIndex) {
      this.getAll({
        controllerId: this.controllerId, 
        startIndex: startIndex,
        pathpart: this.pathpart
      })
      .then(data => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
          this.loadError = true;
        } else {
          if (data.length > 0) {
            this.noMoreData = false;
          } else {
            this.noMoreData = true;
          }
          this.loadError = false;
        }
      });
    },
    dateFormat(val) {
      if (!val) return '';
      const dat = val.split(' '),
      h = dat[1].slice(0, 5);
      return `<span>${dat[0]}</span><span>${h}</span>`
      //return h + '<br />' + dat[0];
    }
  },
}
</script>
<style>
.dat span {
  display: block;
}
@media (min-width: 960px) {
  .dat span {
    display: inline-block;
    padding-left: 8px;
    font-size: 1rem;
  }
}
</style>

