<template>
  <v-card-text v-if="loading" class="d-flex flex-column flex-grow-1 px-0 justify-center center h-min-dialog">
    <v-progress-circular
      :size="120"
      :width="7"
      color="primary"
      indeterminate
      class="install-progress"
    ></v-progress-circular>
  </v-card-text>
  <v-card-text v-else-if="loadError" class="d-flex flex-column flex-grow-1 px-0 justify-center center">
    <v-alert class="r-16 py-5">
      <div class="text-center px-5 pb-3">{{ locales['load-error'] }}</div>
      <div class="text-center">
        <v-btn rounded outlined color="primary" @click="setup()">Odśwież</v-btn>
      </div>
    </v-alert>
  </v-card-text>
  <div v-else-if="scheduleLoaded" class="d-flex flex-column flex-grow-1 px-0">
    <v-toolbar flat class="nobg flex-initial">
      <v-btn v-if="isMobile" icon @click="onClose" color="primary" ref="test">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-text-field
        v-if="nameEditable"
        v-model="newName" 
        required 
        type="text"
        hide-details
        ref="inputname"
      ></v-text-field>
      <v-toolbar-title v-else>{{ savedName || config.label }}</v-toolbar-title>

      <v-btn v-if="isNameEditable && !nameEditable" icon @click="toggleEdit" color="primary">
        <v-icon>edit</v-icon>
      </v-btn>
      <div v-else-if="isNameEditable">
        <v-btn v-if="false" icon @click="saveEdit" outlined small color="primary" class="mx-3">
          <v-icon>check</v-icon>
        </v-btn>
        <v-btn icon @click="toggleEdit" outlined small color="error" class="mx-3">
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      

      <v-spacer></v-spacer>
      <v-btn icon @click="onClose" color="primary" v-if="!isMobile">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex flex-column flex-grow-1 px-0 pt-0">
      <div class="py-2 d-flexx flex-columnx flex-grow-1">
        <v-tabs 
          v-model="tab"
          grow 
          class="cond">
          <v-tab class="px-1" v-for="(item, i) in weekDays" :key="i" :class="{blink: blink[i], empty: !isDayValid(i) }">{{ item.label }}</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" :key="renderKey">
          <v-tab-item
            v-for="(item, i) in weekDays"
            :key="i"
          >
            <v-card flat>
              <v-card-text v-if="Object.keys(schedule[i]).length > 0" class="text-center pt-0 pb-6 px-0">
                <v-simple-table :dense="isLong(i)">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center font-weight-regular text-sm-subtitle-1">{{ locales['hour'] }}</th>
                        <th v-for="par in params" :key="par" class="text-center font-weight-regular text-sm-subtitle-1">{{ paramsConfig[par].label }}</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, hour) in schedule[i]" :key="i+hour" :class="{blinktr: blinkTr == i+hour}">
                        <td>{{ hour }}</td>
                        <td v-for="par in params" :key="par">
                          <span v-if="paramsConfig[par].extra[item[par]]">{{ paramsConfig[par].extra[item[par]] }}</span>
                          <span v-else>{{ item[par] + ' ' + paramsConfig[par].unit }}</span>
                        </td>
                        <td><v-icon color="primary" @click="edit(hour)">edit</v-icon></td>
                        <td><v-icon :color="(hour != '00:00') ? 'error' : 'secondary'" @click="confirmDeleteHour(hour)">clear</v-icon></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-text v-else class="text-center pa-8">{{ locales['schedule-hint-2'] }}</v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <div class="w-100 d-flex justify-center justify-lg-start px-lg-6">
          <v-btn large rounded outlined color="primary" @click="add" class="mr-1"><v-icon>add</v-icon></v-btn>
          <v-btn large rounded text color="primary" @click="copyDialog = true" class="ml-1">{{ locales['copy'] }}</v-btn>
        </div>

        <v-dialog
          v-model="edDialog"
          max-width="360"
          persistent
          overlay-opacity="0.87"
        >
          <v-card>
            <v-card-text>
              <div class="d-flex flex-wrap justify-end w-100 pt-4 px-8">
                
                <div class="d-flex flex-column align-center w-50">
                  <div class="mb-2">
                    {{ locales['hour'] }}
                  </div>
                  <div class="spec-picker">
                    <v-btn x-large icon color="primary" @click="decreaseH" :disabled="this.hourSet == '00'">
                      <v-icon>remove</v-icon>
                    </v-btn>
                    <div class="select-wrapper">
                      <v-select
                        v-model="hourSet"
                        :disabled="edHourIndex == '00:00'"
                        :items="hourItems"
                        color="primary"
                        hide-details
                        class="setter text-h5"
                        outlinedx
                        :menu-props="{ auto: true, 'content-class': 'setter-menu' }"
                        append-icon=""
                      ></v-select>
                    </div>
                    <v-btn x-large icon color="primary" @click="increaseH" :disabled="this.minuteSet == '23'">
                      <v-icon>add</v-icon>
                    </v-btn>
                  </div>
                </div>
                
                <div class="d-flex flex-column align-center w-50 relative">
                  <div class="mb-2">
                    {{ locales['minute'] }}
                  </div>
                  <div class="spec-picker">
                    <v-btn x-large icon color="primary" @click="decreaseM" :disabled="this.minuteSet == '00'">
                      <v-icon>remove</v-icon>
                    </v-btn>
                    <div class="select-wrapper">
                      <v-select
                        v-model="minuteSet"
                        :items="minuteItems"
                        :disabled="edHourIndex == '00:00'"
                        color="primary"
                        hide-details
                        class="setter text-h5"
                        :menu-props="{ auto: true, 'content-class': 'setter-menu' }"
                        append-icon=""
                      ></v-select>
                    </div>
                    <v-btn x-large icon color="primary" @click="increaseM" :disabled="this.minuteSet == '59'">
                      <v-icon>add</v-icon>
                    </v-btn>
                  </div>
                  <div class="time-dots">:</div>
                </div>

              </div>

              <div class="d-flex flex-wrap justify-end w-100 pt-4 px-8">
                <div v-for="par in params" :key="par" class="d-flex flex-column align-center w-50">
                  <div class="w-100 text-center mb-2">
                    <div class="w-100 overflow-ellipsis">{{ paramsConfig[par].label }}</div>
                    <div>{{ paramsConfig[par].unit }}</div>
                  </div>
                  <div class="spec-picker">
                    <v-btn x-large icon color="primary" @click="decreaseP(par)" :disabled="parametersSet[par] == parametersItems[par][0]">
                      <v-icon>remove</v-icon>
                    </v-btn>
                    <div class="select-wrapper">
                      <v-select
                        v-model="parametersSet[par]"
                        :items="parametersItems[par]"
                        item-text="txt"
                        item-value="val"
                        color="primary"
                        hide-details
                        class="setter setter-wide text-h5"
                        :menu-props="{ auto: true, 'content-class': 'setter-menu' }"
                        append-icon=""
                        :key="dynamicKey"
                      ></v-select>
                    </div>
                    <v-btn x-large icon color="primary" @click="increaseP(par)" :disabled="parametersSet[par] == parametersItems[par].slice(-1)">
                      <v-icon>add</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                text
                @click="edDialog = false"
              >
                {{ locales['cancel'] }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="saveEd"
              >
                {{ locales['save'] }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="copyDialog"
          max-width="200"
          overlay-opacity="0.87"
        >
          <v-card>
            <v-list
              flat
            >
              <v-list-item-group
                v-model="pasteTo"
                multiple
              >
                <v-list-item 
                  v-for="(item, i) in weekDays" 
                  :key="i"
                  :disabled="i == tab"
                  class="px-6"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="(i == tab) ? true : active"
                        :color="(i == tab) ? 'accent' : 'primary'"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="copyDialog = false">Anuluj</v-btn>
              <v-btn color="primary" text @click="paste">Wklej</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="deleteDialog"
          max-width="350"
          overlay-opacity="0.87"
        >
          <v-card>
            <v-card-text class="text-subtitle-1 pt-6">Na pewno chcesz usunąć pozycję z harmonogramu?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                text
                @click="deleteDialog = false"
              >
                Anuluj
              </v-btn>
              <v-btn
                color="error"
                text
                @click="deleteHour"
              >
                Usuń
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </div>

      <v-card-actions v-if="config.label" class="pb-5 pb-sm-0 px-3" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
        <v-btn x-large rounded text exact :color="(isMobile) ? 'primary' : 'secondary'" @click="editDialogClose" class="mr-1">{{ locales['cancel'] }}</v-btn>
        <v-btn x-large rounded :text="!isMobile" color="primary" @click="save" :loading="saving" :disabled="saving || loading" class="px-16 px-md-6">{{ locales['save'] }}</v-btn>
      </v-card-actions>
    </v-card-text>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['paramName','onClose'],
  data: () => ({
    id: '',
    name: '',
    active: '',
    editable: '',
    schedule: {},
    tab: null,
    valid: true,
    value_set: undefined,

    copyDialog: false,
    pasteTo: [],
    blink: [],

    hourItems: ['00', '01', '02', '03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
    hourSet: '',
    minuteItems: ['00', '01', '02', '03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'],
    minuteSet: '',
    parametersItems: {},
    parametersSet: {},

    edDialog: false,
    edDayIndex: undefined,
    edHourIndex: undefined,
    blinkTr: null,

    deleteDialog: false,

    renderKey: 1,
    loadError: false,
    saving: false,
    scheduleLoaded: false,

    nameEditable: false,
    newName: '',
    savedName: null,

    dynamicKey: 1
	}),
	computed: {
    ...mapGetters('parameters', ['editDialog', 'paramValue', 'paramConfig', 'currentDevice']),
    ...mapGetters('schedules', ['loading']),
    ...mapGetters(['isMobile']),
    ...mapGetters('locales', ['locales']),
    config() {
      return (this.paramName) ? this.paramConfig(this.paramName) : {}
    },
    isNameEditable() {
      return this.config && (this.config.editLabel === 1 || this.config.editLabel === '1')
    },
    unit() {
      return (this.config.unit == 'C') ? '&deg;C' : this.config.unit
    },
    params() {
      return (this.config && this.config.params) ? Object.keys(this.config.params) : []
    },
    paramsConfig() {
      let conf = []
      this.params.forEach(el => {
        conf[el] = this.paramConfig(el)
        if (this.config.params[el].min) {
          conf[el].min = this.config.params[el].min
        }
        if (this.config.params[el].max) {
          conf[el].max = this.config.params[el].max
        }
        if (this.config.params[el].step) {
          conf[el].step = this.config.params[el].step
        }
        if (this.config.params[el].extra) {
          conf[el].extra = this.config.params[el].extra
        } else {
          conf[el].extra = {}
        }
      })
      return conf
    },
    localDays() {
      return ('week-days' in this.locales) ? this.locales['week-days'].split(',') : []
    },
    weekDays() {
      return (this.localDays.length === 7) ? [
        { label: this.localDays[0] },
        { label: this.localDays[1] },
        { label: this.localDays[2] },
        { label: this.localDays[3] },
        { label: this.localDays[4] },
        { label: this.localDays[5] },
        { label: this.localDays[6] }
      ] : [
        { label: 'PO' },
        { label: 'WT' },
        { label: 'ŚR' },
        { label: 'CZ' },
        { label: 'PT' },
        { label: 'SO' },
        { label: 'NI' }
      ]
    }
  },
  watch: {
    paramName() {
      this.setup()
    },
    editDialog() {
      if (this.editDialog === true) {
        this.$nextTick(function () {
          this.setup()
        })
      }
    }
  },
  created() {
    this.setup()
  },
	methods: {
    ...mapMutations('parameters', ['editDialogClose']),
    ...mapActions('schedules', ['getDefault', 'update']),
    startedOk() {
      //console.log(this.schedule[this.tab]);
      //return (this.schedule[this.tab]) ? this.schedule[this.tab].hasOwnProperty('00:00') : true
      return (this.schedule[this.tab]['00:00']) ? true : false
      //return true;
    },
    toggleEdit() {
      this.newName = this.config.label
      this.nameEditable = !this.nameEditable
      if (this.nameEditable === true) {
        this.$nextTick(()=>{
          this.$refs.inputname.focus()
        });
      } else {
        this.newName = ''
      }
    },
    parseSchedule () {
      const isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }
      
      for (let d = 0; d < this.schedule.length; d += 1) {
        if (typeof this.schedule[d] === 'object') {
          Object.keys(this.schedule[d]).forEach(h => {
            if (typeof this.schedule[d][h] === 'object') {
              Object.keys(this.schedule[d][h]).forEach(p => {
                if (isNumeric(this.schedule[d][h][p])) {
                  this.schedule[d][h][p] = parseFloat(this.schedule[d][h][p]).toString()
                }
              })
            }
          })
        }
      }
    },
    setup () {      
      this.getDefault({
        controllerId: this.currentDevice,
        paramName: this.paramName
      })
      .then((data) => {
        if (data.error) {
          this.loadError = true;
        } else {
          this.schedule = data.schedule
          this.active = data.active
          this.name = data.name
          this.id = data.id
          this.editable = data.edit
          this.scheduleLoaded = true
          this.nameEditable = false
          this.parseSchedule()
        }
      });
      
      //parameters possible values (from config)
      let values = {}

      this.params.forEach(el => {
        values[el] = []

        if (this.paramsConfig[el].options) {
          values[el] = Object.values(this.paramsConfig[el].options)
        } else {
          let firstVal = parseFloat(this.paramsConfig[el].min)
          let lastVal = parseFloat(this.paramsConfig[el].max)
          let step = (this.paramsConfig[el].step) ? parseFloat(this.paramsConfig[el].step) : 1
          for (let i = firstVal; i <= lastVal; i += step) {
            values[el].push({
              val: i.toString(),
              txt: i.toString()
            });
          }
          // extra
          if (typeof this.paramsConfig[el].extra === 'object') {
            Object.keys(this.paramsConfig[el].extra).forEach(k => {
              values[el].unshift({
                val: k,
                txt: this.paramsConfig[el].extra[k]
              })
            })
          }
        }
      })

      this.parametersItems = values
    },
    edit(hour) {
      let hourArr = hour.split(":");
      this.hourSet = hourArr[0];
      this.minuteSet = hourArr[1];
      
      this.params.forEach(par => {
        // this.parametersSet[par] = (this.parametersItems[par].indexOf(this.schedule[this.tab][hour][par]) !== -1) ? this.schedule[this.tab][hour][par].toString() : this.parametersItems[par][0].val
        if (this.parametersItems[par].findIndex(el => el.val == this.schedule[this.tab][hour][par]) !== -1) {
          this.parametersSet[par] = this.schedule[this.tab][hour][par] // .toString() wywalic
        } else {
          this.parametersSet[par] = this.parametersItems[par][0].val
        }
      })
      
      //el to edit
      this.edHourIndex = hour;
      this.edDialog = true;
    },
    add() {
      this.hourSet = '00'
      this.minuteSet = '00'

      this.params.forEach(par => {
        this.parametersSet[par] = this.parametersItems[par][0].val
      })
      
      this.edHourIndex = undefined; //el to edit
      this.edDialog = true;
    },
    saveEd() {
      //delete old
      if (this.edHourIndex) {
        delete this.schedule[this.tab][this.edHourIndex];
      }
      //add updated
      this.schedule[this.tab][`${this.hourSet}:${this.minuteSet}`] = { ...this.parametersSet }
      //sort
      this.schedule[this.tab] = Object.keys(this.schedule[this.tab])
      .sort()
      .reduce((acc, key) => ({
          ...acc, [key]:  this.schedule[this.tab][key]
      }), {});
      
      this.edDialog = false;
      //blink
      this.blinkTr = this.tab + `${this.hourSet}:${this.minuteSet}`;
      setTimeout(() => { this.blinkTr = []; }, 1000);
    },
    confirmDeleteHour(hour) {
      if (hour == '00:00') {
        this.$store.commit('snack/open', { color: "error", text: this.locales['schedule-hint-1'] });
      } else {
        this.edHourIndex = hour;
        this.deleteDialog = true;
      }
    },
    deleteHour() {
      delete this.schedule[this.tab][this.edHourIndex];
      this.renderKey = this.tab + this.edHourIndex;
      this.edHourIndex = undefined;
      this.deleteDialog = false;
    },
    save () {
      this.saving = true
      this.nameEditable = false

      this.update({
        controllerId: this.currentDevice,
        paramName: this.paramName, 
        schedule: this.schedule, 
        id: this.id,
        name: (this.newName) ? this.newName : '' })
      .then(data => {
        this.saving = false;
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
        } else {
          // this.$router.push({ path: '/controllers/' + this.currentDevice })
          this.editDialogClose()
        }
      })
    },
    saveEdit() {
      this.nameEditable = false

      // duplicating save action
      this.saving = true
      this.update({
        controllerId: this.currentDevice,
        paramName: this.paramName, 
        schedule: this.schedule, 
        id: this.id,
        name: this.newName})
      .then(data => {
        this.saving = false;
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
        } else {
          // this.$router.push({ path: '/controllers/' + this.currentDevice })
          // this.editDialogClose()
          console.log('save ok');
        }
      })
    },
    increaseH() {
      let index = this.hourItems.findIndex(el => el == this.hourSet);
      
      // if (!this.startedOk()) {
      if (this.edHourIndex == '00:00') {
        this.$store.commit('snack/open', { color: "error", text: this.locales['schedule-hint-1'] });
      } else if (index < this.hourItems.length - 1) {
        this.hourSet = this.hourItems[index + 1];
      }
    },
    decreaseH() {
      let index = this.hourItems.findIndex(el => el == this.hourSet);
      if (index > 0) {
        this.hourSet = this.hourItems[index - 1];
      }
    },
    increaseM() {
      let index = this.minuteItems.findIndex(el => el == this.minuteSet);
      //if (!this.startedOk()) {
      if (this.edHourIndex == '00:00') {
        this.$store.commit('snack/open', { color: "error", text: this.locales['schedule-hint-1'] });
      } else if (index < this.minuteItems.length - 1) {
        this.minuteSet = this.minuteItems[index + 1];
      }
    },
    decreaseM() {
      let index = this.minuteItems.findIndex(el => el == this.minuteSet);
      if (index > 0) {
        this.minuteSet = this.minuteItems[index - 1];
      }
    },
    increaseP(par) {
      let index = this.parametersItems[par].findIndex(el => el.val == this.parametersSet[par])
      if (index < this.parametersItems[par].length - 1) {
        // this.parametersSet[par] = this.parametersItems[par][index + 1];
        this.$set(this.parametersSet, par, this.parametersItems[par][index + 1].val)
      }
      this.dynamicKey = par + index
    },
    decreaseP(par) {
      let index = this.parametersItems[par].findIndex(el => el.val == this.parametersSet[par]);
      if (index > 0) {
        // this.parametersSet[par] = this.parametersItems[par][index - 1]
        this.$set(this.parametersSet, par, this.parametersItems[par][index - 1].val)
      }
      this.dynamicKey = par + index
    },
    paste() {
      var i;
      if (this.pasteTo.length > 0) {
        for (i = 0; i < this.pasteTo.length; i++) {
          this.schedule[this.pasteTo[i]] = {
            ...this.schedule[this.tab]
          };
          this.blink[this.pasteTo[i]] = true;
        }
      }
      
      setTimeout(() => { this.blink = []; }, 2000);
      this.copyDialog = false;
      this.pasteTo = [];
    },
    isLong(i) {
      return (Object.keys(this.schedule[i]).length > 6) ? true : false
    },
    isDayValid(i) {
      //console.log(typeof this.schedule);
      /*if (typeof this.schedule[i] !== 'undefined') {
        return typeof this.schedule[i]['00:00'] == 'undefined'
      } else {
        return false;
      }
      return i ? true : false*/

      return typeof this.schedule[i]['00:00'] !== 'undefined'
    }
	}
}
//green darken-1
//Vue.set(items, indexOfItem, newValue)
//this.$set(items, indexOfItem, newValue)
</script>

<style>
.v-tab.blink:before {
  animation: blink 2s 1;
}
@keyframes blink
{
  0%      {opacity:0;}
  50%     {opacity:0.38;}
  100%    {opacity:0;}
}
.blinktr {
  animation: blinkbg 0.5s infinite;
  transition: background-color 0.2s;
}
@keyframes blinkbg {
  0%, 49% {
    background-color: rgba(29, 161, 242, .02);
  }
  50%, 100% {
    background-color: rgba(29, 161, 242, .2);
  }
}
</style>