<template>
  <v-card-text v-if="dataComplete" class="d-flex flex-column flex-grow-1">
    <div class="py-16 d-flex flex-column flex-grow-1 justify-center">
      <div v-if="config.prompt" class="text-center pb-4 op-68">{{ config.prompt }}</div>
      <div class="read d-flex justify-center mb-6">
        &nbsp;<span v-text="value_set"></span><span v-if="config.unit == 'C'">&deg;</span><span v-else class="unit">{{ config.unit }}</span>
      </div>
      <div class="mb-2 mb-sm-4">
        <v-slider
          v-model="value_set"
          :min="minValue"
          :max="maxValue"
          :step="step"
          hide-details
        ></v-slider>
        <div class="d-flex justify-space-between px-2 text--secondary text-body-2 text-sm-h5">
          <div>
            <span>{{ minValue }}</span>
            <span v-if="config.unit == 'C'"> &deg;C</span><span v-else> {{ config.unit }}</span>
          </div>
          <div>
            <span>{{ maxValue }}</span>
            <span v-if="config.unit == 'C'"> &deg;C</span><span v-else> {{ config.unit }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center">
        <v-btn x-large rounded outlined color="primary" @click="decrease" class="mx-2 mx-sm-4"><v-icon color="primary">remove</v-icon></v-btn>
        <v-btn x-large rounded outlined color="primary" @click="increase" class="mx-2 mx-sm-4"><v-icon color="primary">add</v-icon></v-btn>
      </div>
    </div>
    <v-card-actions v-if="config.label" class="pb-5 pb-sm-0 px-3 px-sm-0" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
      <v-btn x-large rounded text exact :color="(isMobile) ? 'primary' : 'secondary'" @click="close" class="mr-1">{{ locales['cancel'] }}</v-btn>
      <v-btn x-large rounded :text="!isMobile" color="primary" @click="save" :loading="loading" class="px-16 px-md-6">{{ locales['save'] }}</v-btn>
    </v-card-actions>
  </v-card-text>
   <v-card-text v-else class="d-flex flex-column flex-grow-1 px-0 justify-center center h-min-dialog">
      <v-progress-circular
        :size="120"
        :width="7"
        color="primary"
        indeterminate
        class="install-progress"
      ></v-progress-circular>
   </v-card-text>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['paramName'],
  data: () => ({
		valid: true,
    value_set: undefined,
    loading: false,
    harmonograms: [
      { id: '1', name: 'NA STAŁE' },
      { id: '2', name: 'HARMONOGRAM' }
    ]
	}),
	computed: {
    ...mapGetters('parameters', ['editDialog', 'paramValue', 'paramConfig']),
    ...mapGetters('schedules', ['getSchedule']),
    ...mapGetters(['isMobile']),
    ...mapGetters('locales', ['locales']),
    config() {
      return (this.paramName) ? this.paramConfig(this.paramName) : {}
    },
    minValue() {
      return (this.config.min) ? parseFloat(this.config.min) : 0
    },
    maxValue() {
      return (this.config.max) ? parseFloat(this.config.max) : 100
    },
    step() {
      return (this.config.step) ? parseFloat(this.config.step) : 1
    },
    valueValid() {
      return (this.value_set >= this.minValue && this.value_set <= this.maxValue)
    },
    dataComplete() {
      if (typeof this.minValue !== 'undefined' && typeof this.maxValue !== 'undefined' && typeof this.step !== 'undefined' && typeof this.value_set !== 'undefined' && this.valueValid) {
        return true
      } 

      if (typeof this.minValue === 'undefined') { console.log('Błąd! ' + this.paramName + ' brak [min] w konfiguracji') }
      if (typeof this.maxValue === 'undefined') { console.log('Błąd! ' + this.paramName + ' brak [max] w konfiguracji') }
      if (typeof this.step === 'undefined') { console.log('Błąd! ' + this.paramName + ' brak [step] w konfiguracji') }
      if (typeof this.value_set === 'undefined') { console.log('Błąd! ' + this.paramName + ' brak [value_set]') }
      if (!this.valueValid) { console.log('Błąd! ' + this.paramName + ' Wartość parametru poza zakresem: ' + this.value_set + ' (min: ' + this.minValue + ', max: ' + this.maxValue + ')' ) }
      return false
    },
    /* hasSchedule() {
      return (this.config.schedule) ? true : false
    },
    scheduleOn() {
      return (this.hasSchedule) ? !!this.paramValue(this.config.schedule) : false
    } */
  },
  watch: {
    paramName() {
      this.setup();
    }
  },
  created() {
    this.setup();
  },
	methods: {
    ...mapMutations('parameters', ['editDialogClose', 'setParameter']),
    ...mapActions('parameters', ['update']),
    setup () {
      this.value_set = parseFloat(this.paramValue(this.paramName))
      if (!this.valueValid) {
        console.log(this.paramName + ' Wartość parametru poza zakresem. Przyjmuję wartość [min] ');
        this.value_set = (this.minValue !== 'undefined') ? this.minValue : this.value_set
      }
    },
    decrease () {
      this.value_set = (typeof this.value_set !== 'undefined' && this.value_set >= this.minValue + this.step) ? (this.value_set - this.step) : this.minValue
    },
    increase () {
      this.value_set = (typeof this.value_set !== 'undefined' && this.value_set <= this.maxValue - this.step) ? (this.value_set + this.step) : this.maxValue
    },
    close () {
      this.loading = false;
      this.editDialogClose();
    },
    save () {
      this.loading = true;
      this.update({
        paramName: this.paramName,
        paramValue: this.value_set
      })
      .then(data => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
          this.loading = false;
        } else {
          if (data[this.paramName]) {
            this.$store.commit('snack/open', { color: "success", timeout: 700, text: this.locales['changes-saved'] })
            //this.setParameter({name: this.paramName, value: this.value_set});
            this.setParameter({name: this.paramName, value: data[this.paramName]})
            this.close()
          } else {
            this.$store.commit('snack/open', { color: "error", timeout: 5000, text: this.locales['no-value'] + ': ' + this.paramName })
            this.loading = false
          }
        }
      });
    }
	}
}
</script>
