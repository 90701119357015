<template>
  <div v-if="left !== '00:00:00:00'" class="c-primary p-read d-flex pt-2" :style="{ color: color }">
    <div v-if="distance > 0">
      <span v-if="parseInt(days) > 0" class="mr-3 relative">{{ days }}<small> dni</small></span><span>{{ hours }}:{{ minutes }}:{{ seconds }}</span>
    </div>
    <div v-else>
      <div>00:00:00</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['controllerId', 'paramName', 'left', 'color'],
  data () {
    return {
      endTime: undefined,
      distance: 0
    }
  },
  computed: {
    ...mapGetters(['second', 'clock']),
    ...mapGetters('parameters', ['updateTime']),
    days() {
      return ("0" + Math.floor(this.distance / (1000 * 60 * 60 * 24))).slice(-2)
    },
    hours() {
      return ("0" + Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2)
    },
    minutes() {
      return ("0" + Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60))).slice(-2)
    },
    seconds() {
      return ("0" + Math.floor((this.distance % (1000 * 60)) / 1000)).slice(-2)
    },
    /* isDemo() {
      return !!this.byId(this.controllerId).demo
    } */
  },
  watch: {
    second() {
      this.updateLeft()
    },
    /*updateTime() {
      this.updateLeft(true)
    },*/
    /* left() {
      this.updateLeft(true)
    } */
  },
  mounted() {
    this.updateLeft()
    this.startSecond()
  },
  methods: {
    ...mapActions('parameters', ['getAll']),
    ...mapActions(['startSecond', 'stopSecond']),
    updateLeft() {
      const left = this.left.split(':')
      const leftTime = (parseInt(left[0]) * 1000 * 60 * 60 * 24) + (parseInt(left[1]) * 1000 * 60 * 60) + (parseInt(left[2]) * 1000 * 60) + ((left[3]) ? (parseInt(left[3]) * 1000) : 0)

      const now = new Date().getTime()
      this.distance = (this.updateTime + leftTime) - now;

      if (this.distance <= 0 || this.left === '00:00:00'  || this.left === '00:00:00:00') {
        this.distance = 0
        this.stopSecond()
        setTimeout(() => { this.getAll() }, 1500)
      }

      /*
      if (this.$store.state['parameters'].endTime[this.paramName]) {
        this.endTime = this.$store.state['parameters'].endTime[this.paramName]
        console.log('START this.endTime = ' + new Date(this.endTime).toUTCString())
      }
      
      const now = new Date().getTime()

      if (sync || !this.endTime) {
        const left = this.left.split(':')
        const leftTime = (parseInt(left[0]) * 1000 * 60 * 60 * 24) + (parseInt(left[1]) * 1000 * 60 * 60) + (parseInt(left[2]) * 1000 * 60) + ((left[3]) ? (parseInt(left[3]) * 1000) : 0)
        // const leftTime = (parseInt(left[0]) * 1000 * 60 * 60 * 24) + (parseInt(left[1]) * 1000 * 60 * 60) + (parseInt(left[2]) * 1000 * 60)
        
        // too big left difference (api/front)
        const test = this.endTime - (now + leftTime)

        if (!this.endTime || test > 5000 || test < -5000) {
          this.$store.state['parameters'].endTime[this.paramName] = new Date(now + leftTime).getTime()
          this.endTime = this.$store.state['parameters'].endTime[this.paramName]
          console.log('test = ' + test);
          console.log('aktu this.endTime = ' + new Date(this.endTime).toUTCString())
        }
      }

      this.distance = this.endTime - now
      
      if (this.distance <= 0 || this.left === '00:00:00'  || this.left === '00:00:00:00') {
        this.$store.state['parameters'].endTime[this.paramName] = undefined
        this.distance = 0
        this.stopSecond()
        setTimeout(() => { this.getAll() }, 1500)
      }
      */
    }
  }
}
</script>