<template>
	<v-container
		class="fill-height flex-column justify-lg-center"
		fluid
	>
		<div class="flex-grow-1 d-flex align-center w-100">
      <v-row
        align="center"
        justify="center"
        class="flex-grow-1"
      >
        <v-col
          cols="10"
          sm="8"
          md="4"
        >
          <transition name="fade" mode="out-in">
            <div v-if="success" key="1">
							<div class="text-h5 text--primary mb-8">{{ locales['password-changed'] }}</div>
							<p>Możesz zalogować się korzystając z nowego hasła</p>
						</div>
						<div v-else key="2">
              <div class="text-h5 text--primary mb-8">Ustaw nowe hasło</div>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field 
                  prepend-inner-icon="vpn_key"
                  label="Nowe hasło" 
                  :type="passType ? 'text' : 'password'" 
                  v-model="passwd" 
                  :rules="[rules.required, rules.min]"
                  :append-icon="passType ? 'visibility' : 'visibility_off'" 
                  @click:append="passType = !passType"
                  required
                  :loading="loading"
                  counter
                ></v-text-field>
                <v-text-field 
                  prepend-inner-icon="vpn_key"
                  label="Powtórz hasło" 
                  :type="pass2Type ? 'text' : 'password'" 
                  v-model="passwd2" 
                  :rules="[rules.required, rules.min, passwordsMatch]"
                  :append-icon="pass2Type ? 'visibility' : 'visibility_off'" 
                  @click:append="pass2Type = !pass2Type"
                  required
                  :loading="loading"
                  counter
                ></v-text-field>
                <div class="text-caption mt-3 text--secondary">&#42; Minimum 8 znaków</div>
              </v-form>
            </div>

          </transition>
        </v-col>
      </v-row>
    </div>
    <div class="flex-shrink-1 h-action-column d-flex flex-column">
      <v-btn v-if="!success" x-large rounded color="primary" @click="changePass" :loading="loading" class="w-btn mb-2">Zmień hasło</v-btn>
      <v-btn v-if="!success" x-large rounded text color="primary" to="/" class="w-btn">Anuluj</v-btn>
      <v-btn v-if="success" x-large rounded outlined text color="primary" to="/login" class="w-btn">Zaloguj się</v-btn>
    </div>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	props: ['token'],
  data: () => ({
		valid: true,
		passwd: '',
    passType: false,
    passwd2: '',
		pass2Type: false,
    rules: {
      required: value => !!value || 'Podaj hasło',
      min: value => value.length >= 8 || 'Min 8 znaków'
    },
    success: false
	}),
	computed: {
		...mapGetters('profile', ['profile', 'loading']),
    ...mapGetters('locales', ['locales']),
    passwordsMatch() {
      return () => (this.passwd === this.passwd2) || 'Hasła muszą być jednakowe'
    },
  },
	methods: {
		...mapActions('profile', ['newpass']),
		changePass() {
			if (this.$refs.form.validate()) {
				this.newpass({
          password: this.passwd,
          password2: this.passwd2,
          token: this.token
				})
				.then(data => {
					if (data.error) {
            this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
					} else {
						this.success = true;
					}
				});
			}
    },
	}
}
</script>

<style>
.v-card.no-bg {
	background-color: transparent !important;
}
</style>