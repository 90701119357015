<template>
  <v-card
    class="mx-auto pa-4 pa-sm-6 rounded-card"
    elevation="0"
    @click="ed"
    v-if="config"
    :class="{ disabled: parameter.disabled === '1' || parameter.disabled === 1, alert: parameter.alert }"
  >
    <h5 class="op-68 font-weight-regular text-body-1 text-sm-h6 text-truncate">{{ config.label }}</h5>
    <div class="d-flex align-center" :class="{ 'justify-center': !icon }">
      <div v-if="icon" class="p-ico d-flex align-center justify-center pr-3 pr-sm-8">
        <v-img v-if="icon.includes('.')" :src="iconDir + icon" contain :class="{blink: parameter.animation === 'blink', rotate: parameter.animation === 'rotate'}"></v-img>
        <v-icon v-else>{{ icon }}</v-icon>
      </div>
      <div class="d-flex flex-column justify-center p-h">
        <div>
          <div v-if="config.setParam" class="d-inline-flex p-set c-primary">
            <span v-if="setConfig.options">{{ setConfig.options[setParameter.value] }}</span>
            <span v-else>{{ setParameter.value | valueFilter }}</span>
            <span v-if="setConfig.unit == 'C'">&deg;</span>
            <span v-else class="unit">{{ setConfig.unit }}</span>
          </div>
        </div> 
        <div class="p-read d-flex align-start" v-if="parameter.value" :class="{'c-primary': isEditable || config.setMenu, 'flex-column': config.unit && config.unit.length > 3 }" :style="{ color: parameterColor }">
          <span v-if="config.options && parameter.left && parameter.left === '00:00:00:00'" class="text-option op-68 off">{{ locales['off'] }}</span>
          <span v-else-if="config.options" class="text-option">{{ (typeof config.options[parameter.value] === 'object') ? config.options[parameter.value].label : config.options[parameter.value] }}</span>
          <span v-else>{{ parameter.value | valueFilter }}</span>
          <span v-if="config.unit == 'C'">&deg;</span>
          <span v-else-if="config.unit" class="unit" v-text="config.unit"></span>
          <!--<v-icon v-if="config.options" class="text-option ml-2">expand_more</v-icon>-->
        </div>
        <counter-left v-if="parameterLeft && parameterLeft != '00:00:00:00'" :controllerId="controllerId" :paramName="data.param" :left="parameterLeft" :color="parameterColor" />
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CounterLeft from './CounterLeft.vue';
export default {
  props: ['controllerId','data'],
  components: {
    CounterLeft
  },
  computed: {
    ...mapGetters('parameters', ['param', 'paramValue', 'paramConfig']),
    ...mapGetters('profile', ['dark']),
    ...mapGetters('locales', ['locales']),
    //...mapGetters('schedules', ['getSchedule']),
    parameter() {
      return this.param(this.data.param)
    },
    config() {
      return this.paramConfig(this.data.param)
    },
    setParameter() {
      return (this.config.setParam) ? this.param(this.config.setParam) : {}
    },
    setConfig() {
      return (this.config.setParam) ? this.paramConfig(this.config.setParam) : {}
    },
    isEditable() {
      return +this.config.edit === 1
    },
    icon() {
      return this.parameter.icon
    },
    iconDir () {
      return '/img/' + ((this.dark) ? 'dark/' : 'light/')
    },
    parameterColor() {
      return this.parameter.color || null
    },
    scheduleVal() {
      if (this.config.schedule) {
        let data = this.paramValue(this.config.schedule);
        return (data) ? data : null
      } else {
        return null
      }
    },
    parameterLeft() {
      return this.parameter.left
    },
    isDisabled() {
      return Object.prototype.hasOwnProperty.call(this.parameter, "disabled") && +this.parameter.disabled === 1
    },
    isConfigAlert() {
      return Object.prototype.hasOwnProperty.call(this.config, "alert") && this.config.alert !== ''
    }
  },
  methods: {
    ...mapMutations('parameters', ['editDialogOpen']),
    ed() {
      if (this.isDisabled) { 
        if (this.parameter.alert) {
          this.$store.commit('snack/open', { color: "error", text: this.parameter.alert });
        }
      } else if (!this.isEditable && this.isConfigAlert) {
        this.$store.commit('snack/open', { color: "error", text: this.config.alert });
      } else if (this.config.setMenu) {
        this.$router.push({ path: `/controllers/${this.controllerId}/menu/${this.config.setMenu}` })
      } else if (this.isEditable || this.scheduleVal) {
        this.editDialogOpen(this.data.param);
        return true;
      } else if (this.config.setParam) {
        this.editDialogOpen(this.config.setParam);
        return true;
      } else if (this.parameter.alert) { 
        this.$store.commit('snack/open', { color: "error", text: this.parameter.alert });
      } else {
        return false
      }
    }
  },
  filters: {
    valueFilter: function (value) {
      if (value == 0) { return 0 }
      else if (!value) return ''
      if (isNaN(value)) {
        return value
      } else {
        // return (value.toString().indexOf('.') > -1) ? parseFloat(value).toLocaleString() : value;
        return value;
      }
    },
  }
}

/* value = value.toString()

if (value.toString().indexOf('.0') > -1) {
          return parseFloat(value).toFixed()
        } else {
*/
// return value.charAt(0).toUpperCase() + value.slice(1)
// .toFixed(1)
</script>
