<template>
  <v-row dense class="relative">
    <v-col cols="6">
      <v-card
        class="mx-auto pa-4 pa-sm-6 rounded-card"
        elevation="0"
        @click="ed(4)"
        :class="{ disabled: parameter[4].disabled === '1' || parameter[4].disabled === 1, alert: parameter[4].alert }"
      >
        <h5 class="op-68 font-weight-regular text-body-1 text-sm-h6 text-truncate">{{ config[0].label }}</h5>
        
        <div class="p-h-aside"></div>

        <div class="d-flex align-center p-h-one" :class="{ 'justify-center': !parameter[0].icon }">
          <div v-if="parameter[0].icon" class="p-ico d-flex align-center justify-center pr-3 pr-sm-6">
            <v-img v-if="parameter[0].icon.includes('.')" :src="iconDir + parameter[0].icon" contain></v-img>
            <v-icon v-else>{{ parameter[0].icon }}</v-icon>
          </div>
          <div class="d-flex flex-column justify-center">
            <!--<div class="text-right">
              <div v-if="config.setParam" class="d-inline-flex p-set c-primary">
                <span v-if="setConfig.options">{{ setConfig.options[setParameter.value] }}</span>
                <span v-else>{{ setParameter.value | valueFilter }}</span>
                <span v-if="setConfig.unit == 'C'">&deg;</span>
                <span v-else class="unit">{{ setConfig.unit }}</span>
              </div>
            </div>-->
            <div class="p-read d-flex align-start" v-if="parameter[0].value !== undefined" :class="{'c-primary': +config[0].edit === 1 || config[0].setMenu, 'flex-column': config[0].unit && config[0].unit.length > 3 }" :style="{ color: parameter[0].color || false }">
              <span v-if="config[0].options" class="text-option">{{ (typeof config[0].options[parameter[0].value] === 'object') ? config[0].options[parameter[0].value].label : config[0].options[parameter[0].value] }}</span>
              <span v-else>{{ parameter[0].value | valueFilter }}</span>
              <span v-if="config[0].unit == 'C'">&deg;</span>
              <span v-else-if="config[0].unit" class="unit" v-text="config[0].unit"></span>
            </div>
          </div>
        </div>

        <div class="d-flex justify-center pr-5 pr-sm-0 p-ico p-h-center">
          <v-icon>light_mode</v-icon>
          <div v-if="parameter[6] && parameter[6].icon" class="pos-1 p-ico-small"><v-icon :style="{ color: parameter[6].color || false }">{{ parameter[6].icon }}</v-icon></div>
        </div>

        <div class="d-flex align-center p-h-one" :class="{ 'justify-center': !parameter[2].icon }">
          <div v-if="parameter[2].icon" class="p-ico d-flex align-center justify-center pr-3 pr-sm-6">
            <v-img v-if="parameter[2].icon.includes('.')" :src="iconDir + parameter[2].icon" contain></v-img>
            <v-icon v-else>{{ parameter[2].icon }}</v-icon>
          </div>
          <div class="d-flex flex-column justify-center">
            <!--<div class="text-right">
              <div v-if="config.setParam" class="d-inline-flex p-set c-primary">
                <span v-if="setConfig.options">{{ setConfig.options[setParameter.value] }}</span>
                <span v-else>{{ setParameter.value | valueFilter }}</span>
                <span v-if="setConfig.unit == 'C'">&deg;</span>
                <span v-else class="unit">{{ setConfig.unit }}</span>
              </div>
            </div>-->
            <div class="p-read d-flex align-start" v-if="parameter[2].value !== undefined" :class="{'c-primary': +config[2].edit === 1 || config[2].setMenu, 'flex-column': config[2].unit && config[2].unit.length > 3 }" :style="{ color: parameter[2].color || false }">
              <span v-if="config[2].options" class="text-option">{{ (typeof config[2].options[parameter[2].value] === 'object') ? config[2].options[parameter[2].value].label : config[2].options[parameter[2].value] }}</span>
              <span v-else>{{ parameter[2].value | valueFilter }}</span>
              <span v-if="config[2].unit == 'C'">&deg;</span>
              <span v-else-if="config[2].unit" class="unit" v-text="config[2].unit"></span>
            </div>
          </div>
        </div>

        <div class="d-flex justify-space-between align-center p-h-aside">
          <div class="d-flex font-weight-regular text-h6 text-sm-h4 pl-4">
            <div v-if="parameter[8] && parameter[8].value !== undefined" class="px-2" :style="{ color: (parameter[8].color) ?  parameter[8].color : false }">{{ parameter[8].value }}</div>
          </div>
          <div v-if="parameter[4]" class="d-flex">
            <div v-if="parameter[4].icon" class="p-ico-small d-flex align-center justify-center pr-2 pr-sm-3">
              <v-img v-if="parameter[4].icon.includes('.')" :src="iconDir + parameter[4].icon" contain></v-img>
              <v-icon v-else>{{ parameter[4].icon }}</v-icon>
            </div>
            <div class="d-flex flex-column justify-center">
              <div class="p-set d-flex align-start" v-if="parameter[4].value !== undefined" :class="{'c-primary': +config[4].edit === 1, 'flex-column': config[4].unit && config[4].unit.length > 3 }" :style="{ color: parameter[4].color || false }">
                <span v-if="config[4].options" class="text-option">{{ (typeof config[4].options[parameter[4].value] === 'object') ? config[4].options[parameter[4].value].label : config[4].options[parameter[4].value] }}</span>
                <span v-else>{{ parameter[4].value | valueFilter }}</span>
                <span v-if="config[4].unit == 'C'">&deg;</span>
                <span v-else-if="config[4].unit" class="unit" v-text="config[4].unit"></span>
              </div>
            </div>
          </div>
        </div>

        <h5 class="op-68 font-weight-regular text-body-1 text-sm-h6 text-truncate">{{ config[2].label }}</h5>

      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card
        class="mx-auto pa-4 pa-sm-6 rounded-card"
        elevation="0"
        @click="ed(5)"
        :class="{ disabled: parameter[5].disabled === '1' || parameter[5].disabled === 1, alert: parameter[5].alert }"
      >
        <h5 class="op-68 font-weight-regular text-body-1 text-sm-h6 text-truncate">{{ config[1].label }}</h5>
        
        <div class="p-h-aside"></div>

        <div class="d-flex align-center p-h-one" :class="{ 'justify-center': !parameter[1].icon }">
          <div v-if="parameter[1].icon" class="p-ico d-flex align-center justify-center pr-3 pr-sm-6">
            <v-img v-if="parameter[1].icon.includes('.')" :src="iconDir + parameter[1].icon" contain></v-img>
            <v-icon v-else>{{ parameter[1].icon }}</v-icon>
          </div>
          <div class="d-flex flex-column justify-center p-hx">
            <!--<div class="text-right">
              <div v-if="config.setParam" class="d-inline-flex p-set c-primary">
                <span v-if="setConfig.options">{{ setConfig.options[setParameter.value] }}</span>
                <span v-else>{{ setParameter.value | valueFilter }}</span>
                <span v-if="setConfig.unit == 'C'">&deg;</span>
                <span v-else class="unit">{{ setConfig.unit }}</span>
              </div>
            </div>-->
            <div class="p-read d-flex align-start" v-if="parameter[1].value !== undefined" :class="{'c-primary': +config[1].edit === 1 || config[1].setMenu, 'flex-column': config[1].unit && config[1].unit.length > 3 }" :style="{ color: parameter[1].color || false }">
              <span v-if="config[1].options" class="text-option">{{ (typeof config[1].options[parameter[1].value] === 'object') ? config[1].options[parameter[1].value].label : config[1].options[parameter[1].value] }}</span>
              <span v-else>{{ parameter[1].value | valueFilter }}</span>
              <span v-if="config[1].unit == 'C'">&deg;</span>
              <span v-else-if="config[1].unit" class="unit" v-text="config[1].unit"></span>
            </div>
          </div>
        </div>

        <div class="d-flex justify-center pl-5 pl-sm-0 p-ico p-h-center"><v-icon>home</v-icon></div>

        <div class="d-flex align-center p-h-one" :class="{ 'justify-center': !parameter[3].icon }">
          <div v-if="parameter[3].icon" class="p-ico d-flex align-center justify-center pr-3 pr-sm-6">
            <v-img v-if="parameter[3].icon.includes('.')" :src="iconDir + parameter[3].icon" contain></v-img>
            <v-icon v-else>{{ parameter[3].icon }}</v-icon>
          </div>
          <div class="d-flex flex-column justify-center p-hx">
            <!--<div class="text-right">
              <div v-if="config.setParam" class="d-inline-flex p-set c-primary">
                <span v-if="setConfig.options">{{ setConfig.options[setParameter.value] }}</span>
                <span v-else>{{ setParameter.value | valueFilter }}</span>
                <span v-if="setConfig.unit == 'C'">&deg;</span>
                <span v-else class="unit">{{ setConfig.unit }}</span>
              </div>
            </div>-->
            <div class="p-read d-flex align-start" v-if="parameter[3].value !== undefined" :class="{'c-primary': +config[3].edit === 1 || config[3].setMenu, 'flex-column': config[3].unit && config[3].unit.length > 3 }" :style="{ color: parameter[3].color || false }">
              <span v-if="config[3].options" class="text-option">{{ (typeof config[3].options[parameter[3].value] === 'object') ? config[3].options[parameter[3].value].label : config[3].options[parameter[3].value] }}</span>
              <span v-else>{{ parameter[3].value | valueFilter }}</span>
              <span v-if="config[3].unit == 'C'">&deg;</span>
              <span v-else-if="config[3].unit" class="unit" v-text="config[3].unit"></span>
            </div>
          </div>
        </div>

        <div class="d-flex justify-start align-center p-h-aside">
          <div v-if="parameter[5]" class="d-flex">
            <div v-if="parameter[5].icon" class="p-ico-small d-flex align-center justify-center pr-2 pr-sm-3">
              <v-img v-if="parameter[5].icon.includes('.')" :src="iconDir + parameter[5].icon" contain></v-img>
              <v-icon v-else>{{ parameter[5].icon }}</v-icon>
            </div>
            <div class="d-flex flex-column justify-center">
              <div class="p-set d-flex align-start" v-if="parameter[5].value !== undefined" :class="{'c-primary': +config[5].edit === 1, 'flex-column': config[5].unit && config[5].unit.length > 3 }" :style="{ color: parameter[5].color || false }">
                <span v-if="config[5].options" class="text-option">{{ (typeof config[5].options[parameter[5].value] === 'object') ? config[5].options[parameter[5].value].label : config[5].options[parameter[5].value] }}</span>
                <span v-else>{{ parameter[5].value | valueFilter }}</span>
                <span v-if="config[5].unit == 'C'">&deg;</span>
                <span v-else-if="config[5].unit" class="unit" v-text="config[5].unit"></span>
              </div>
            </div>
          </div>
        </div>

        <h5 class="op-68 font-weight-regular text-body-1 text-sm-h6 text-truncate">{{ config[3].label }}</h5>

      </v-card>
    </v-col>

    <div class="rekuper"></div>
    <div v-if="parameter[7] && parameter[7].value" class="pos-2 font-weight-regular text-h5 text-sm-h4" :style="{ color: (parameter[7].color) ?  parameter[7].color : false }">{{ parameter[7].value }}</div>
    
  </v-row>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
export default {
  props: ['controllerId','data'],
  computed: {
    ...mapGetters('parameters', ['param', 'paramValue', 'paramConfig']),
    ...mapGetters('profile', ['dark']),
    parameter() {
      const params = []
      if (this.data.items) {
        for (let i = 0; i < this.data.items.length; i++) {
          params[i] = this.param(this.data.items[i].param)
        }
      }
      return params
    },
    config() {
      const params = []
      if (this.data.items) {
        for (let i = 0; i < this.data.items.length; i++) {
          params[i] = this.paramConfig(this.data.items[i].param)
        }
      }
      return params
    },
    iconDir () {
      return '/img/' + ((this.dark) ? 'dark/' : 'light/')
    }
  },
  methods: {
    ...mapMutations('parameters', ['editDialogOpen']),
    ed(paramIndex) {
      if (this.parameter[paramIndex].disabled && this.parameter[paramIndex].alert) { 
        this.$store.commit('snack/open', { color: "error", text: this.parameter[paramIndex].alert });
      } else if (this.config[paramIndex].setMenu) {
        this.$router.push({ path: `/controllers/${this.controllerId}/menu/${this.config[paramIndex].setMenu}` })
      } else if (+this.config[paramIndex].edit === 1) {
        this.editDialogOpen(this.data.items[paramIndex].param);
        return true;
      } /* else if (this.config[paramIndex].setParam) {
        this.editDialogOpen(this.config[paramIndex].setParam);
        return true;
      } else if (this.setConfig.edit) {
        this.editDialogOpen(this.data2.param);
        return true;
      } */
      else if (this.parameter[paramIndex].alert) { 
        this.$store.commit('snack/open', { color: "error", text: this.parameter[paramIndex].alert });
      } else {
        return false
      }
    },
  },
  filters: {
    valueFilter: function (value) {
      if (value == 0) { return 0 }
      else if (!value) return ''
      if (isNaN(value)) {
        return value
      } else {
        // return (value.toString().indexOf('.') > -1) ? parseFloat(value).toLocaleString() : value;
        return value;
      }
    },
  }
}
</script>

<style scoped>
  .rekuper {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: rgba(255,255,255,0.0);
    background-image: url(/img/dark/recuperator.svg);
    background-size: 44%;
    background-position: center;
    pointer-events: none;
  }
  .theme--light .rekuper {
    background-image: url(/img/light/recuperator.svg);
  }
  .pos-1 {
    position: absolute;
    z-index: 5;
    right: 4vw;
    top: 50%;
    transform: translateY(-50%)
  }
  .pos-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 5vw);
  }
  @media (min-width: 760px) {
    .rekuper {
      background-size: 38%;
    }
    .pos-1 {
      right: 30px;
    }
    .pos-2 {
      transform: translate(-50%, 36px);
    }
  }
  /*.rekuper:after {
    content: "";
    display: block;
    padding-bottom: 75%;
  }*/
  .relative {
    position: relative;
  }
  
</style>