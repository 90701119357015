<template>
	<v-container
		class="fill-height flex-column justify-md-center"
		fluid
	>
		<div class="flex-grow-1 flex-md-grow-0 d-flex align-center w-100">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <div class="text-h5 text--primary mb-8">{{ locales['login'] }}</div>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            autocomplete="off"
          >
            <v-text-field
              v-model="email" 
              required 
              :rules="emailRules"
              label="E-mail"
              name="email"
              prepend-inner-icon="alternate_email"
              type="text"
              :loading="loading"
            ></v-text-field>
            <v-text-field 
              prepend-inner-icon="vpn_key"
              :label="locales['password']"
              :type="passType ? 'text' : 'password'" 
              v-model="passwd" 
              :rules="passwordRules"
              :append-icon="passType ? 'visibility' : 'visibility_off'" 
              @click:append="passType = !passType"
              required
              :loading="loading"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <div class="flex-shrink-1 h-action-column w-100 pt-md-12">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <v-btn x-large rounded color="primary" @click="loginUser" :loading="loading" class="w-100 mb-2">{{ locales['login'] }}</v-btn>
          <v-btn x-large rounded text color="primary" to="/login/remind" class="w-100">{{ locales['password-remind'] }}</v-btn>
        </v-col>
      </v-row>
    </div>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	data: () => ({
		valid: true,
		email: undefined,
		emailRules: [
			v => !!v || 'Podaj adres e-mail',
			v => /.+@.+\..+/.test(v) || 'E-mail jest nieprawidłowy',
		],
		passwd: undefined,
		passwordRules: [ v => !!v || 'Podaj hasło' ],
		passType: false,
		errorMessage: false
	}),
	computed: {
		...mapGetters('profile', ['loading']),
    ...mapGetters('locales', ['locales', 'locale']),
  },
	methods: {
		...mapActions('profile', ['login']),
		loginUser() {
      if (this.$refs.form.validate()) {
				this.login({
					email: this.email,
					password: this.passwd,
          lang: this.locale.id
				})
				.then(data => {
					if (data.error) {
						this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
					} else {
						this.$router.push({ path: '/controllers' })
					}
				});
			}
		}
	}
}
</script>

<style>
.v-card.no-bg {
	background-color: transparent !important;
}
</style>