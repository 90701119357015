<template>
  <v-app :class="{ bggray: showBackground }">
    <v-app-bar
      app
      elevate-on-scroll
    >
      <v-btn v-if="$route.meta.nav == 1" icon @click="$router.go(-1)" color="primary">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-btn v-if="$route.meta.nav == 2" icon @click="drawer = !drawer" color="primary">
        <v-icon>menu</v-icon>
      </v-btn>

      <v-toolbar-title v-if="$route.meta.title" color="accent" class="font-weight-bold">{{ pageTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <refresh-btn v-if="refreshBtn" :refreshModule="refreshBtn" />
      <v-progress-linear
        :active="loading"
        :indeterminate="true"
        absolute
        top
        color="primary"
      ></v-progress-linear>
      <localebtn v-if="!logged" />
      <!--<v-btn v-else icon @click="toSettings" color="primary">
        <v-icon>account_circle</v-icon>
      </v-btn>-->
    </v-app-bar>

    <v-main>
      <transition :name="animationName" mode="out-in">
        <router-view v-if="localesLoaded" />
      </transition>
      <snack />
    </v-main>

    <v-navigation-drawer
      v-model="drawer"
      fixedx
      temporaryx
      class="text-h4"
      app
      v-if="logged"
    >
      <v-list v-if="controllerId" class="pt-0" flatx>
        <v-list-item
          to="/controllers"
          link
          :inactive="!controllerId"
          exact
          color="primary"
          key="s"
          class="w-100 text-center menu-logo py-5 py-md-8"
        >
          <v-list-item-content>
            <svg class="gohome-img embed" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 10.896 11.248"><defs><linearGradient id="a" x1=".502" y1="1.024" x2=".502" y2=".016" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#068dce"/><stop offset="1" stop-color="#2bb1e6"/></linearGradient><linearGradient id="b" x1="-43.473" y1="-558.949" x2="-43.473" y2="-556.311" xlink:href="#a"/><linearGradient id="c" x1="-36.963" y1="-532.639" x2="-36.963" y2="-529.416" xlink:href="#a"/><linearGradient id="d" x1="-45.182" y1="-561.147" x2="-45.182" y2="-558.52" xlink:href="#a"/></defs><path data-name="Path 1" d="M8.68 9.14V6.53a.67.67 0 1 0-1.33 0v2.61a1.13 1.13 0 0 0-.45.86 1.12 1.12 0 0 0 2.23 0 1.13 1.13 0 0 0-.45-.86Z" fill="url(#a)" transform="translate(-2.551 -2.042)"/><path class="negativ" data-name="Path 2" d="M.389 5.738a.39.39 0 0 1-.27-.67l5-4.89a.62.62 0 0 1 .87 0l4.79 4.78a.392.392 0 0 1-.55.56L5.519.848l-4.85 4.78a.43.43 0 0 1-.28.11Z" fill="#fff"/><path data-name="Path 3" d="M8.249 11.248h-5.45a1.25 1.25 0 0 1-1.25-1.29v-3.83a.39.39 0 0 1 .39-.39.4.4 0 0 1 .39.39v3.83a.47.47 0 0 0 .47.47h5.45a.47.47 0 0 0 .47-.47v-3.83a.4.4 0 0 1 .39-.39.39.39 0 0 1 .39.39v3.83a1.25 1.25 0 0 1-1.25 1.29Z" fill="#26ade3"/><path class="negativ" data-name="Path 4" d="M5.669 7.538v-1.82a.205.205 0 0 0-.41 0v1.82a.505.505 0 1 0 .41 0Z" fill="#fff"/><path data-name="Path 5" d="M6.76 6.85a.17.17 0 0 1 .17.15.18.18 0 0 1-.17.17H6.2A.18.18 0 0 1 6 7a.17.17 0 0 1 .17-.17Z" fill="url(#b)" transform="translate(-2.551 -2.042)"/><path data-name="Path 6" d="M6.76 7.64a.18.18 0 0 1 .17.18.18.18 0 0 1-.17.18H6a.18.18 0 0 1-.18-.17.18.18 0 0 1 .18-.19Z" fill="url(#c)" transform="translate(-2.551 -2.042)"/><path data-name="Path 7" d="M6.76 8.44a.18.18 0 0 1 .17.17.17.17 0 0 1-.17.17H6.2a.17.17 0 0 1-.2-.17.18.18 0 0 1 .17-.17Z" fill="url(#d)" transform="translate(-2.551 -2.042)"/></svg>
          </v-list-item-content>
          <v-btn v-if="isMobile && controllerId" icon class="back-home-ico">
            <v-icon>arrow_back_ios_new</v-icon>
          </v-btn>
        </v-list-item>

        <template 
          v-for="(item, i) in controllerMenuItems"
        >
          <v-list-group v-if="item.items" :key="i" :value="item.expand=='1' || undefined">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </template>
            <template v-for="(subItem,j) in item.items">
              <v-list-item
                :key="j"
                :to="'/controllers/' + controllerId + subItem.path"
                link
                exact
                color="primary"
              >
                <v-list-item-icon>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-item
            v-else
            :key="i"
            :to="'/controllers/' + controllerId + item.path"
            link
            exact
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </template>
          
        <v-list-item  
          v-if="!controllerId"
          link
          @click="logoutUser"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ locales['logout'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list v-else class="pt-0" flatx>
        <v-list-item
          color="primary"
          key="s"
          class="w-100 text-center menu-logo py-5 py-md-8"
        >
          <v-list-item-content>
            <svg class="gohome-img embed" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 10.896 11.248"><defs><linearGradient id="a" x1=".502" y1="1.024" x2=".502" y2=".016" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#068dce"/><stop offset="1" stop-color="#2bb1e6"/></linearGradient><linearGradient id="b" x1="-43.473" y1="-558.949" x2="-43.473" y2="-556.311" xlink:href="#a"/><linearGradient id="c" x1="-36.963" y1="-532.639" x2="-36.963" y2="-529.416" xlink:href="#a"/><linearGradient id="d" x1="-45.182" y1="-561.147" x2="-45.182" y2="-558.52" xlink:href="#a"/></defs><path data-name="Path 1" d="M8.68 9.14V6.53a.67.67 0 1 0-1.33 0v2.61a1.13 1.13 0 0 0-.45.86 1.12 1.12 0 0 0 2.23 0 1.13 1.13 0 0 0-.45-.86Z" fill="url(#a)" transform="translate(-2.551 -2.042)"/><path class="negativ" data-name="Path 2" d="M.389 5.738a.39.39 0 0 1-.27-.67l5-4.89a.62.62 0 0 1 .87 0l4.79 4.78a.392.392 0 0 1-.55.56L5.519.848l-4.85 4.78a.43.43 0 0 1-.28.11Z" fill="#fff"/><path data-name="Path 3" d="M8.249 11.248h-5.45a1.25 1.25 0 0 1-1.25-1.29v-3.83a.39.39 0 0 1 .39-.39.4.4 0 0 1 .39.39v3.83a.47.47 0 0 0 .47.47h5.45a.47.47 0 0 0 .47-.47v-3.83a.4.4 0 0 1 .39-.39.39.39 0 0 1 .39.39v3.83a1.25 1.25 0 0 1-1.25 1.29Z" fill="#26ade3"/><path class="negativ" data-name="Path 4" d="M5.669 7.538v-1.82a.205.205 0 0 0-.41 0v1.82a.505.505 0 1 0 .41 0Z" fill="#fff"/><path data-name="Path 5" d="M6.76 6.85a.17.17 0 0 1 .17.15.18.18 0 0 1-.17.17H6.2A.18.18 0 0 1 6 7a.17.17 0 0 1 .17-.17Z" fill="url(#b)" transform="translate(-2.551 -2.042)"/><path data-name="Path 6" d="M6.76 7.64a.18.18 0 0 1 .17.18.18.18 0 0 1-.17.18H6a.18.18 0 0 1-.18-.17.18.18 0 0 1 .18-.19Z" fill="url(#c)" transform="translate(-2.551 -2.042)"/><path data-name="Path 7" d="M6.76 8.44a.18.18 0 0 1 .17.17.17.17 0 0 1-.17.17H6.2a.17.17 0 0 1-.2-.17.18.18 0 0 1 .17-.17Z" fill="url(#d)" transform="translate(-2.551 -2.042)"/></svg>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          :to="item.path || null"
          link
          exact
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  
          link
          @click="logoutUser"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ locales['logout'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-overlay :value="!localesLoaded">
      <v-progress-circular
        indeterminate
        size="72"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import Snack from './components/Snack';
import Localebtn from './components/Localebtn.vue';
import RefreshBtn from './components/RefreshBtn.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'App',
  components: {
    Snack, Localebtn, RefreshBtn
  },
  data: () => ({
    animationName: undefined,
    drawer: null,
    registration: null,
    localesLoaded: false
  }),
  computed: {
    ...mapGetters('profile', ['logged','dark','locale']),
    ...mapGetters('locales', ['locales']),
    ...mapGetters(['loading','pageTitle','isMobile']),
    ...mapGetters('menu', ['menuLewe']),
    controllerId() {
      return this.$route.params.controllerId
    },
    refreshBtn() {
      return this.$route.meta.refresh
    },
    showBackground() {
      return this.$route.meta.background || false
    },
    menuItems() {
      if (this.logged) {
        return [
          { name: this.locales['my-devices'], icon: 'home', path: '/controllers' },
          { name: this.locales['options'], icon: 'settings', path: '/settings' }
        ]
      } else {
        return []
      }
    },
    controllerMenuItems() {
      return this.menuLewe.items || []
    },
  },
  watch: {
    $route(to, from){
      if (!this.isMobile) {
        this.animationName = "fade";
      } else {
        this.animationName = to.meta.level > from.meta.level ? "slide-left" : "slide-right";
      }
      //this.animationName = to.path.split('/').length < from.path.split('/').length ? 'slide-right' : 'slide-left'
    }
  },
  beforeMount() {
    this.updateApiUrl()
    .then(() => {
      this.restorePofile()
        .then(() => {
          this.$vuetify.theme.dark = this.dark === true;
          // this.$i18n.locale = (this.locale) ? this.locale : 'pl';
          this.loadLocales(null)
          .then((data) => {
            if (data.error) {
              this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
            } else {
              this.localesLoaded = true
            }
          })
        })
    })
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.startClock()

    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    // Refresh all open app tabs when a new service worker is installed.

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      });

      // firebase messages
      this.$messaging.onMessage(this.$messaging.messaging, (payload) => {
        const notificationBody = (payload.notification.body) ? payload.notification.body : 'Komunikat sterownika';
        this.$store.commit('snack/open', { color: "error", timeout: 10000, text: notificationBody });
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions('profile', ['restorePofile', 'logout']),
    ...mapActions('locales', ['loadLocales']),
    ...mapActions(['startClock', 'updateApiUrl']),
    ...mapMutations(['setIsMobile']),
    toSettings() {
      this.$router.push({ path: '/settings' })
    },
    handleResize() {
      this.setIsMobile(window.innerWidth < 960);
    },
    showRefreshUI (e) {
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail
      //this.$store.commit('snack/open', { color: "success", text: this.locales['new-version'] });
      if (window.confirm(this.locales['new-version'])) {
        this.refreshApp();
      }
    },
    refreshApp () {
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return }
      this.registration.waiting.postMessage('SKIP_WAITING');
    },
    logoutUser() {
      this.logout();
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style>
.v-app-bar--hide-shadow {
  background-color: transparent !important;
}
.v-application--wrap {
  overflow-x: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
/*.fade-enter-active {
  transition-delay: .25s;
}*/
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 150ms ease;
  position: absolute;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-5%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(5%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(5%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-5%, 0, 0);
}
</style>