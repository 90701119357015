// const apiUrl = (window.location.hostname == 'localhostx') ? 'http://apibornico/api' : 'https://api.e-panel24.com/api';
// const apiUrl = 'https://pwa-backend.bornico.pl/api';

/*const authHeader = () => {
  let profile = JSON.parse(localStorage.getItem('profile'));

  if (profile && profile.token) {
      return { 
          'Authorization': 'Bearer ' + profile.token
      }
  } else {
      return {}
  }
}*/

const badResponse = () => {
  return {
    error: {
      code: '101'
    }
  }
}

const loadApiUrl = () => {
  console.log('loadApiUrl')
  return fetch('/config.json?t=' + new Date().getTime(), {
    method: 'GET'
  })
  .then(response => response.json())
  .then(data => {
    if (data.apiUrl) {
      localStorage.setItem('apiUrl', data.apiUrl);
    }
    return data
  })
  .catch(() => badResponse());
}

const apiUrl = () => {
  const url = localStorage.getItem('apiUrl'); // production api
  // const url = 'http://apibornico/api'; // dev api
  return (url) ? url : undefined
}

const getToken = () => {
  let profile = JSON.parse(localStorage.getItem('profile'));

  return (profile && profile.token) ? profile.token : undefined

  /* if (profile && profile.token) {
    return profile.token 
  } else {
    return undefined
  } */
}

/*const objToQuery = (obj) => {
  return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}*/

export { apiUrl, getToken, badResponse, loadApiUrl }