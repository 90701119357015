import users from '../../api/users'

const state = () => ({
  all: [],
  permissions: ['Właściciel','Pełna kontrola','Tylko odczyt','Brak uprawnień'],
  loading: false
})

const getters = {
  all(state) {
    return state.all
  },
  byId: (state) => (id) => {
    return state.all.find(el => el.id == id)
  },
  permissions(state) {
    return state.permissions
  },
  loading(state) {
    return state.loading
  }
}

const mutations = {
  setAll: (state, data) => {
    state.all = data
  },
  setLoading: (state, data) => {
    state.loading = data
  },
}

const actions = {
  getAll({commit}, {controllerId, pathpart}) {
    commit('setLoading', true, { root: true });
    commit('setAll', []);
    return users.getAll(controllerId, pathpart)
    .then(data => {
      if (!data.error) { commit('setAll', data); }
      commit('setLoading', false, { root: true });
      return data;
    })
  },
  update({commit, dispatch}, {controllerId, email, permission}) {
    commit('setLoading', true);
    return users.update(controllerId, email, permission)
    .then(data => {
      if (!data.error) { dispatch('getAll', {controllerId: controllerId}); }
      commit('setLoading', false);
      return data;
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}