<template>
  <div class="relative">
    <v-card
      class="mx-auto pa-3 rounded-card"
      elevation="0"
      @click="onCardClick"
    >
      <div class="d-flex justify-space-between mb-4">
        <span><v-switch v-model="online" color="primary" readonly inset hide-details class="ma-0"></v-switch></span>
      </div>
      <div class="text-truncate text-subtitle-1 text-sm-h5" :class="{ off: !online }">{{ data.displayName || data.controllerId }}</div>
      <div class="d-flex justify-space-between align-end mt-1">
        <div class="text-value" :class="{ off: !online, 'c-primary': online }">
          <span v-if="data.value || data.value === '0'">{{ data.value | valueFilter }}</span><v-icon v-else size="40" color="error">report_gmailerrorred</v-icon>
          <span v-if="data.value && data.unit == 'C'">&deg;</span>
          <span v-else-if="data.value && data.unit" class="unit" v-text="data.unit"></span>
        </div>
        <div v-if="data.demo && data.demo != 0" class="text-body-2 text-sm-subtitle-1 op-38 pr-1">DEMO</div>
      </div>
    </v-card>
    <div class="more-btn">
      <v-btn
        icon
        large
        @click="$emit('deleteClick', data.controllerId)"
      >
        <v-icon class="off">clear</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    online() {
      return parseInt(this.data.online) === 1
    }
  },
  methods: {
    onCardClick() {
      if (this.data.value || this.data.value === '0') {
        this.$router.push('/controllers/' + this.data.controllerId)
      } else {
        this.$emit('message', 'inactive-device')
      }
    }
  },
  filters: {
    valueFilter: function (value) {
      if (value == 0) { return 0 }
      else if (!value) return ''
      if (isNaN(value)) {
        return value
      } else {
        // return (value.toString().indexOf('.') > -1) ? parseFloat(value).toLocaleString() : value;
        return value;
      }
    },
  }
}
</script>

<style scoped>
.more-btn {
  position: absolute;
  top: 6px;
  right: 6px;
}
.more-btn .v-btn > .v-btn__content .v-icon {
  opacity: 0.38;
}
.text-value {
    font-size: 2.5rem !important;
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif !important;
}
@media (min-width: 600px) {
  .text-value {
    font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif !important;
  }
}
</style>