<template>
  <v-card-text class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column flex-grow-1 justify-center">
      <div v-if="config.prompt" class="text-center pb-4">{{ config.prompt }}</div>
      <v-slide-y-reverse-transition leave-absolute hide-on-leave>
      <v-list v-if="step == 1" :dense="longList" rounded class="nobg option-list">
        <v-list-item-group v-model="value_set" mandatory active-class="listactive" color="primary" class="text-center">
          <v-list-item
            v-for="(item, i) in config.options"
            :key="i"
            :value="i.toString()"
            @click="selectProgram"
          >
            <v-list-item-content>
              <v-list-item-title class="option" v-text="(config.unit) ? item.label + ' ' + config.unit : item.label"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div v-else-if="setupDone" class="d-flex flex-column align-center">
        <h2 class="text-center text-h6 mb-4 op-68">{{ optionConfig.label }}</h2>
        <div class="d-flex flex-wrap justify-end w-100 w-60-l pt-2">

          <div class="d-flex flex-column align-center w-third">
            <div class="mb-2">Dni</div>
            <div class="spec-picker">
              <v-btn x-large icon color="primary" @click="decreaseD" :disabled="daySet == '00'">
                <v-icon>remove</v-icon>
              </v-btn>
              <div class="select-wrapper">
                <v-select
                  v-model="daySet"
                  :items="dayItems"
                  color="primary"
                  hide-details
                  class="setter"
                  outlinedx
                  :menu-props="{ auto: true, 'content-class': 'setter-menu' }"
                  append-icon=""
                ></v-select>
              </div>
              <v-btn x-large icon color="primary" @click="increaseD" :disabled="this.daySet == '28'">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </div>

          <!--<div class="d-flex flex-column justify-center px-3 text-h5"></div>-->
          
          <div class="d-flex flex-column align-center w-third">
            <div class="mb-2">
              Godziny
            </div>
            <div class="spec-picker">
              <v-btn x-large icon color="primary" @click="decreaseH" :disabled="hourSet == '00'">
                <v-icon>remove</v-icon>
              </v-btn>
              <div class="select-wrapper">
                <v-select
                  v-model="hourSet"
                  :items="hourItems"
                  color="primary"
                  hide-details
                  class="setter text-h5"
                  outlinedx
                  :menu-props="{ auto: true, 'content-class': 'setter-menu' }"
                  append-icon=""
                ></v-select>
              </div>
              <v-btn x-large icon color="primary" @click="increaseH" :disabled="this.minuteSet == '23'">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </div>

          <!--<div class="d-flex flex-column justify-center pt-4 text-h5">:</div>-->
          
          <div class="d-flex flex-column align-center w-third relative">
            <div class="mb-2">
              Minuty
            </div>
            <div class="spec-picker">
              <v-btn x-large icon color="primary" @click="decreaseM" :disabled="minuteSet == '00'">
                <v-icon>remove</v-icon>
              </v-btn>
              <div class="select-wrapper">
                <v-select
                  v-model="minuteSet"
                  :items="minuteItems"
                  color="primary"
                  hide-details
                  class="setter"
                  :menu-props="{ auto: true, 'content-class': 'setter-menu' }"
                  append-icon=""
                ></v-select>
              </div>
              <v-btn x-large icon color="primary" @click="increaseM" :disabled="this.minuteSet == '59'">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
            <div class="time-dots">:</div>
          </div>

        </div>

        <div v-if="optionConfig.edit == '1'" class="d-flex flex-wrap justify-end w-100 w-60-l pt-4">
          <div v-for="par in params" :key="par" class="d-flex flex-column align-center w-third">
            <div class="w-100 text-center mb-2">
              <div class="w-100 overflow-ellipsis">{{ paramsConfig[par].label }}</div>
              <div>{{ paramsConfig[par].unit }}</div>
            </div>
            <div class="spec-picker">
              <v-btn x-large icon color="primary" @click="decreaseP(par)" :disabled="parametersSet[par] == parametersItems[par][0]">
                <v-icon>remove</v-icon>
              </v-btn>
              <div class="select-wrapper">
                <v-select
                  v-model="parametersSet[par]"
                  :items="parametersItems[par]"
                  item-text="txt"
                  item-value="val"
                  color="primary"
                  hide-details
                  class="setter"
                  :menu-props="{ auto: true, 'content-class': 'setter-menu' }"
                  append-icon=""
                  :key="dynamicKey"
                ></v-select>
              </div>
              <v-btn x-large icon color="primary" @click="increaseP(par)" :disabled="parametersSet[par] == parametersItems[par].slice(-1)">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div v-else class="d-flex flex-wrap justify-end w-100 w-60-l pt-8 pb-4">
          <div v-for="par in params" :key="par" class="d-flex flex-column align-center w-third">
            <div class="w-100 text-center mb-2">
              <div class="w-100 overflow-ellipsis op-68">{{ paramsConfig[par].label }}</div>
            </div>
            <div>
              <div class="setter text-h5">{{ parametersSet[par] }} <span>{{ paramsConfig[par].unit }}</span></div>
            </div>
          </div>
        </div>

      </div>
      </v-slide-y-reverse-transition>

    </div>
    <v-card-actions class="flex-shrink-1 pb-5 pb-sm-0 px-3 px-sm-0" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
      <v-btn x-large rounded text exact :color="(isMobile) ? 'primary' : 'secondary'" @click="close" class="mr-1">{{ locales['cancel'] }}</v-btn>
      <v-btn v-if="step === 1 && parameter.left != '00:00:00:00'" x-large rounded :text="!isMobile" color="error" @click="stop" :loading="loading" class="px-16 px-md-6">{{ locales['turn-off'] }}</v-btn>
      <v-btn v-if="step === 2" x-large rounded :text="!isMobile" color="primary" @click="save" :loading="loading" class="px-16 px-md-6">{{ locales['save'] }}</v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
import {mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  props: ['paramName'],
  data: () => ({
		valid: true,
    value_set: undefined,
    loading: false,

    dayItems: ['00', '01', '02', '03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28'],
    daySet: '',
    hourItems: ['00', '01', '02', '03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
    hourSet: '',
    minuteItems: ['00', '01', '02', '03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'],
    minuteSet: '',
    parametersItems: {},
    parametersSet: {},

    step: 1,
    setupDone: false,
    dynamicKey: 1
	}),
	computed: {
    ...mapGetters('parameters', ['currentDevice', 'editDialog', 'param', 'paramConfig']),
    ...mapGetters(['isMobile']),
    // ...mapGetters('profile', ['locale']),
    ...mapGetters('locales', ['locales']),
    parameter() {
      return this.param(this.paramName)
    },
    config() {
      return (this.paramName) ? this.paramConfig(this.paramName) : {}
    },
    params() {
      return (this.config && this.config.params) ? Object.keys(this.config.params) : []
    },
    paramsConfig() {
      let conf = []
      this.params.forEach(el => {
        conf[el] = this.paramConfig(el)
        if (this.config.params[el].min) {
          conf[el].min = this.config.params[el].min
        }
        if (this.config.params[el].max) {
          conf[el].max = this.config.params[el].max
        }
        if (this.config.params[el].step) {
          conf[el].step = this.config.params[el].step
        }
        if (this.config.params[el].extra) {
          conf[el].extra = this.config.params[el].extra
        } else {
          conf[el].extra = {}
        }
      })
      return conf
    },
    parameterTime() {
      return (this.parameter.time) ? this.parameter.time.split(':') : ['00','01','00']
    },
    parameterLeft() {
      return (this.parameter.left) ? this.parameter.left.split(':') : []
    },
    optionConfig() {
      return (this.value_set) ? this.config.options[this.value_set] : ''
    },
    longList() {
      return Object.keys(this.config.options).length > 8 ? true : false
    }
  },
  watch: {
    paramName() {
      this.setup();
    },
    editDialog() {
      if (this.editDialog === true) {
        this.$nextTick(function () {
          this.step = 1
          this.setup()
        })
      }
    }
  },
  created() {
    this.setup();
  },
	methods: {
    ...mapMutations('parameters', ['editDialogClose', 'setParameter']),
    ...mapActions('parameters', ['updateTemporary','getAll']),
    close () {
      this.loading = false;
      this.editDialogClose();
    },
    setup() {      
      if (this.step === 1) 
      {
        // set choosen program
        this.value_set = this.parameter.value.toString()
      } 
      else 
      {
        this.setupDone = false

        //params possible values/options (from config)
        let values = {}
        
        this.params.forEach(el => {
          values[el] = []

          if (this.paramsConfig[el].options) {
            values[el] = Object.values(this.paramsConfig[el].options)
          } else {
            let firstVal = parseFloat(this.paramsConfig[el].min)
            let lastVal = parseFloat(this.paramsConfig[el].max)
            let step = (this.paramsConfig[el].step) ? parseFloat(this.paramsConfig[el].step) : 1
            for (let i = firstVal; i <= lastVal; i += step) {
              values[el].push({
                val: i.toString(),
                txt: i.toString()
              });
            }
            // extra
            if (typeof this.paramsConfig[el].extra === 'object') {
              Object.keys(this.paramsConfig[el].extra).forEach(k => {
                values[el].unshift({
                  val: k,
                  txt: this.paramsConfig[el].extra[k]
                })
              })
            }
          }
        })
      
        this.parametersItems = values
        
        
        // time values
        if (this.value_set === this.parameter.value.toString()) { // edit active program - time from dane
          this.daySet = ("0" + this.parameterTime[0]).slice(-2)
          this.hourSet = ("0" + this.parameterTime[1]).slice(-2)
          this.minuteSet = ("0" + this.parameterTime[2]).slice(-2)
        } else if (this.optionConfig.time) { // start new program - time from config
          let defaultTime = this.optionConfig.time.split(':')
          this.daySet = ("0" + defaultTime[0]).slice(-2)
          this.hourSet = ("0" + defaultTime[1]).slice(-2)
          this.minuteSet = ("0" + defaultTime[2]).slice(-2)
        } else { // default time
          this.daySet = '00'
          this.hourSet = '01'
          this.minuteSet = '00'
        }

        // params values
        this.params.forEach(par => {
          if (this.optionConfig.edit !== '1') {
            // get values from option config
            this.parametersSet[par] = this.optionConfig[par].toString()
          } else if (this.parametersItems[par] && this.parametersItems[par].findIndex(el => el.val == this.param(par).value.toString()) !== -1) {
            // get values from dane
            this.parametersSet[par] = this.param(par).value.toString()
          } else {
            // get default values
            this.parametersSet[par] = this.parametersItems[par][0].val
          }
        })

        this.setupDone = true
      }

    },
    stop() {
      this.loading = true
      const parametersSetZero = []
      this.params.forEach(par => {
        parametersSetZero[par] = this.param(par).value.toString()
      })

      this.updateTemporary({
        paramName: this.paramName,
        paramValue: this.value_set,
        time: '0:0:0',
        params: parametersSetZero
      })
      .then(data => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
          this.loading = false;
        } else {
          if (data[this.paramName]) {
            this.close();
            setTimeout(() => { this.getAll(); }, 700)
          } else {
            this.$store.commit('snack/open', { color: "error", timeout: 5000, text: this.locales['no-value'] + ': ' + this.paramName });
            this.loading = false;
          }
        }
      })
    },
    save () {
      this.loading = true
      this.updateTemporary({
        paramName: this.paramName,
        paramValue: this.value_set,
        time: this.daySet + ':' + this.hourSet + ':' + this.minuteSet,
        params: this.parametersSet
      })
      .then(data => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
          this.loading = false;
        } else {
          if (data[this.paramName]) {
            this.close();
            // refresh data
            setTimeout(() => { this.getAll(); }, 700)
          } else {
            this.$store.commit('snack/open', { color: "error", timeout: 5000, text: this.locales['no-value'] + ': ' + this.paramName });
            this.loading = false;
          }
        }
      })
    },
    startedOk() {
      return true;
    },
    selectProgram() {
      this.step = 2
      this.$nextTick(function () {
        this.setup()
      })
    },
    increaseD() {
      let index = this.dayItems.findIndex(el => el == this.daySet);
      if (index < this.dayItems.length - 1) {
        this.daySet = this.dayItems[index + 1];
      }
    },
    decreaseD() {
      let index = this.dayItems.findIndex(el => el == this.daySet);
      if (index > 0) {
        this.daySet = this.dayItems[index - 1];
      }
    },
    increaseH() {
      let index = this.hourItems.findIndex(el => el == this.hourSet);
      if (index < this.hourItems.length - 1) {
        this.hourSet = this.hourItems[index + 1];
      }
    },
    decreaseH() {
      let index = this.hourItems.findIndex(el => el == this.hourSet);
      if (index > 0) {
        this.hourSet = this.hourItems[index - 1];
      }
    },
    increaseM() {
      let index = this.minuteItems.findIndex(el => el == this.minuteSet);
      if (index < this.minuteItems.length - 1) {
        this.minuteSet = this.minuteItems[index + 1];
      }
    },
    decreaseM() {
      let index = this.minuteItems.findIndex(el => el == this.minuteSet);
      if (index > 0) {
        this.minuteSet = this.minuteItems[index - 1];
      }
    },
    increaseP(par) {
      let index = this.parametersItems[par].findIndex(el => el.val == this.parametersSet[par])
      if (index < this.parametersItems[par].length - 1) {
        // this.parametersSet[par] = this.parametersItems[par][index + 1];
        this.$set(this.parametersSet, par, this.parametersItems[par][index + 1].val)
      }
      this.dynamicKey = par + index
    },
    decreaseP(par) {
      let index = this.parametersItems[par].findIndex(el => el.val == this.parametersSet[par]);
      if (index > 0) {
        // this.parametersSet[par] = this.parametersItems[par][index - 1]
        this.$set(this.parametersSet, par, this.parametersItems[par][index - 1].val)
      }
      this.dynamicKey = par + index
    },
	}
}
</script>

<style scoped>
.option-list .v-list-item {
  border: 2px solid transparent;
}
.option-list .v-list-item.listactive {
  border: 2px solid #2196f3;
}
.option-list .listactive.v-list-item--active::before {
  opacity: 0;
}
.v-list-item__title {
  font-size: 1.25rem;
}
.v-list:not(.v-list--dense) .listactive .v-list-item__title {
  font-weight: 700;
  font-size: 1.5rem;
}

.option-list .v-list-item--dense, 
.v-list--dense.option-list .v-list-item {
  min-height: 32px;
}
.option-list .v-list-item--dense .v-list-item__content, 
.v-list--dense.option-list .v-list-item .v-list-item__content {
  padding: 4px 0;
}

</style>