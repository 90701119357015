import { apiUrl, getToken, badResponse } from "./api";

export default {
  /*get (id) {
    return fetch('https://www.radiorekord.pl/tracklist/get_user.php?id=' + id + '&t=' + new Date().getTime())
    .then(response => response.json())
  },*/
  getAll() {
    const formData = new FormData();
    formData.append('token', getToken());
    return fetch(apiUrl() + '/sterownik/lista?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  create(controllerId, displayName, demo) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('displayName', displayName);
    if (demo) { formData.append('demo', demo); }
    formData.append('token', getToken());
    
    return fetch(apiUrl() + '/sterownik/dodaj?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  update(controllerId, displayName) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('displayName', displayName);
    formData.append('token', getToken());
    
    return fetch(apiUrl() + '/sterownik/edytuj?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  remove(controllerId) {
    const formData = new FormData();
    formData.append('controllerId', controllerId);
    formData.append('token', getToken());
    
    return fetch(apiUrl() + '/sterownik/usun?t=' + new Date().getTime(), {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .catch(() => badResponse());
  },
  restore() {
    const data = localStorage.getItem('controllers');
    if (data) {
      return JSON.parse(data)
    } else {
      return {}
    }
  },
  store(data) {
    localStorage.setItem('controllers', JSON.stringify(data));
  }
}
