import alarms from '../../api/alarms'

const state = () => ({
  all: [],
  icons: ['error_outline','wifi_off','error','info'],
  colors: ['error','warning','secondary','secondary'],
  loading: false
})

const getters = {
  all(state) {
    return state.all
  },
  byId: (state) => (id) => {
    return state.all.find(el => el.id == id)
  },
  icons(state) {
    return state.icons
  },
  colors(state) {
    return state.colors
  },
  loading(state) {
    return state.loading
  }
}

const mutations = {
  setAll: (state, data) => {
    state.all = data
  },
  appendAll: (state, data) => {
    state.all.push(...data);
  },
  setLoading: (state, data) => {
    state.loading = data
  },
}

const actions = {
  getAll({commit}, {controllerId, startIndex, pathpart}) {
    commit('setLoading', true, { root: true });
    if (startIndex == 1) { commit('setAll', []); }
    return alarms.getAll(controllerId, startIndex, pathpart)
    .then(data => {
      if (startIndex > 1) {
        commit('appendAll', data);
      } else {
        commit('setAll', data);
      }
      commit('setLoading', false, { root: true });
      return data;
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}