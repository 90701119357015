<template>
  <v-btn @click="refresh" :loading="loading" text icon class="op-68">
    <v-icon>sync</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: ['refreshModule'],
  data: () => ({
    loading: false
  }),
  methods: {
    refresh() {
      this.loading = true
      if (this.refreshModule == 'controllers') {
        this.$store.dispatch('controllers/getAll')
        .then(() => {
          setTimeout(() => { this.loading = false }, 400)
        })
      } else {
        this.$store.dispatch('parameters/getAll')
        .then(() => {
          setTimeout(() => { this.loading = false }, 400)
        })
      }
    }
  }
}
</script>